import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramService } from 'src/app/shared/services/program.service';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';


@Component({
  selector: 'app-deal-left-side-menu',
  templateUrl: './deal-left-side-menu.component.html',
  styleUrls: ['./deal-left-side-menu.component.scss']
})
export class DealLeftSideMenuComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  dealId: any;
  tips = true;
  showIdea = false;
  convertedID='';
  dealCurrentStatus:any={};
  percent=0;
  colorCode='#FFC000';
  isRedirectFrom = false;
  sub:any;
  firstSepperLable = this.staticText?.opportunity?.create_edit


  @ViewChild('stepper') stepper: MatStepper | any;
  stepName = localStorage.getItem('stepLabel');
  dealDetails: any;
  showTip() {
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private ps: ProgramService,
    private ds: DealStructureService,
    private spinner:NgxSpinnerService,
    private cdRef:ChangeDetectorRef
  ) {
    
   }

  ngOnInit(): void {
    
      this.route.params.subscribe((res: any) => {
        this.dealId = atob(res.id);
        this.convertedID = res.id;
        this.getDealById(this.dealId);
      })
  }


  getDealById(id: any) {
    this.ds.getDealDetails(this.dealId).subscribe((res: any) => {
      this.dealDetails = res;
      this.dealCurrentStatus = this.dealDetails?.status;
      // this.ps.setStatusValue(this.dealCurrentStatus);
      if(this.router?.url?.includes('/setup')){  
        this.percent=25;
        this.colorCode='#dd2c54';
      }else if(this.router?.url?.includes('/scope')){  
        this.percent=50;
        this.colorCode='#daa479';
      }else if(this.router?.url?.includes('/toe')){  
        this.percent=75;
        this.colorCode='#1a85d6';
      }else if(this.router?.url?.includes('/award-deal')){  
        this.percent=99;
        this.colorCode='#07b797';
        // this.dealCurrentStatus.action_complete_label = "completed";
      }  
    });
  }

  ngAfterViewInit() {
    // this.setStepperData();
  //   this.ps.getStatusValue().subscribe((newValue) => {
  //     if(newValue?.slug !=this.dealCurrentStatus?.slug){
  //       this.dealCurrentStatus = newValue;
  //       if(newValue?.slug == 'opportunity_draft'){  
  //         this.percent=20;
  //         this.colorCode='#dd2c54';
  //       }else if(newValue?.slug == 'opportunity_created' || newValue?.slug =='opportunity_modified'){  
  //         this.percent=40;
  //         this.colorCode='#daa479';
  //       }else if(newValue?.slug == 'opportunity_published'){  
  //         this.percent=50;
  //         this.colorCode='#1a85d6';
  //       }else if(newValue?.slug == 'opportunity_evaluating'){  
  //         this.percent=75;
  //         this.colorCode='#FFC000';
  //       }else if(newValue?.slug == 'opportunity_sow_signed'){  
  //         this.percent=100;
  //         this.colorCode='#07b797';
  //       }else if(newValue?.slug == 'opportunity_cancelled'){  
  //         this.percent=100;
  //         this.colorCode='#dd2c54';
  //       }
  //     }    
  //  });    
  }
  // setStepperData() {
  //   let lastUrlSegment = this.router.url.split('/').pop();
    
  //   if (lastUrlSegment == 'proposal-evaluation' || lastUrlSegment == 'summary' || lastUrlSegment == 'award-deal') {
  //     this.stepper.selectedIndex = 1;
  //     this.firstSepperLable = this.staticText?.opportunity?.edit;
  //   }
  //   else if (lastUrlSegment == 'setup' || lastUrlSegment == 'scope' || lastUrlSegment == 'terms' || lastUrlSegment == 'more-info' || lastUrlSegment =="preview" || lastUrlSegment=="evaluation") {
  //     this.stepper.selectedIndex = 0;
  //   }
  //   this.cdRef.detectChanges(); 
  // }

  clickDeliveryContract() {
    this.router.navigate([`deal/${btoa(this.dealId)}/delivery-contract`]);
  }

  clickAddcontract() { this.router.navigate([`toe/${btoa(this.dealId)}/add-terms`]); }
  onStepChange(evt:any){
    // if(evt.selectedIndex ==0){
    //   this.router.navigate([`rfx/${btoa(this.opportunityId)}/opportunity/preview`]);
    // }else if(evt.selectedIndex ==1){
    //   this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation/`]);
    // }
  }
  navigateTO(page:string){   
    this.router.navigate([`/deal/${this.convertedID}/${page}`]);
    //this.spinner.show();
    // this.ps.getOppStatus(this.dealId).subscribe((res: any) => {
    //  // this.spinner.hide();
    //   this.ps.setStatusValue(res?.status);
    //   if(this.isRedirectFrom){
    //     this.router.navigate([`/rfx/${this.convertedID}/opportunity/${page}`],{queryParams: { redirectFrom: 'Project',id:  this.projectID  }});
    //   }else{
    //     this.router.navigate([`/rfx/${this.convertedID}/opportunity/${page}`]);
    //   }
    // },err=>this.spinner.hide());
  }
  
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }
}
