

import { ChangeDetectorRef, Component, OnDestroy, OnInit, Pipe, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ScopeService } from 'src/app/shared/services/scope.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
var _ = require('lodash');
var BreakException = {};
@Component({
  selector: 'app-scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss']
})
export class ScopeComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('stepper') stepper: MatStepper|any;
  stepStates:any=[];
  dealId: any;
  initalLoad = false;
  side = false;
  side1 = false;
  searchText = '';
  search = false;
  showAddField: boolean | any;
  scopeItems: any;
  allscopeItems: any;
  allcustomScopeItems:any;
  newScopeItems: any;
  textContents = {
    searchText: 'Parameter searched is not found in the list'
  }
  sideHelp = true;
  selectedItems: any[] = [];
  updateItems: any;
  tips = true;
  scopeList: any[] = [];
  parameter: any;
  stepperCategory: any[] = [];
  cloneStepperCategory: any[] = [];
  cloneCustomCategory: any[] = [];
  sideParam = false;
  newParameterItems: any;
  selectedParameterItems: any[] = [];
  parameterForm: FormBuilder | any;
  stepperIndex = 0;
  parameteClick: any;
  addedParameterItems: any;
  sideParameter: any;
  submitted = false;
  newArr: FormArray | any;
  scope_data: any = {};
  parameterIndex: any;
  addColumnIndex = 0;
  showNext = false;
  showUpload = false;
  showAddCustom = false;
  showCustomOnly = false;
  showAddCustomParameter = false;
  CusParameterName: any;
  cusScopeDescription: any;
  cusScopeTitle: any;
  customScopeParameter: any;
  newCustomParameterItems: any;
  categoryName: any;
  customCategory: any;
  showCustomScope = false;
  customScopeItems: any;
  sideScopeParameter: any;
  newCustomScopeItems: any;
  newCustomCategory: any;
  showCustomButton = true;
  selectedCategoryItems: any[] = [];
  customTab = false;
  selectedScope: any[] = [];
  scopeIndex: any;
  totalFiles: any[] = [];
  matTab = true;
  dealInfo: any;

  public tabIndex1 = 0;
  itemCategory: any;
  name: any;
  scopeArray: any[] = [];
  filterPayload: any[] = [];
  patchResponse: any;
  newScope: any;
  newStepperCategory: any[] = [];
  index = 0;
  @ViewChild('tabGroup') tabGroup: MatTabGroup | any;
  scopeArrayToFilter: any[] = [];
  categoryArrayToFilter: any[] = [];
  patchResponseItem: any;
  inc = 0;
  maxChars = 100;
  parameterArrayToFilter: any[] = [];
  scopeData: any;
  disableFields: boolean=false;
  stepperCategoryInitial: any[]=[];
  selectedCategoryItemsInitial: any[]=[];
  
  isRedirectFrom = false;
  projectID:any='';
  sub:any;

  hasChange:boolean = false;

  dealScopeData:any;
  tagDetails: any;

  scopeCreateForm: FormArray | any;
  scopeDescForm: FormGroup | any;
  scopeCustomCreateForm: FormGroup | any;
  breadcrumb: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private scopeService: ScopeService,
    private ps: ProgramService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private ref: ChangeDetectorRef,
    private dataService: DataService,
    private ds : DealStructureService,
    // private formScopePipe:FormGroupNameScopePipe,
    private breadCrumService: BreadcrumbService
  ) {
   
  }

  ngOnInit(): void {
    this.sub = this.route
    .queryParams
    .subscribe((params:any) => {
      this.isRedirectFrom = params['redirectFrom'] || false;
      this.projectID = params['id']; 
    });
    const perm: any = JSON.parse(localStorage.getItem('permission') || '{}');
    this.permissionsService.loadPermissions(perm.permissions);
    setTimeout(() => {
      this.showNext = true;
    }, 1100)
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.dealId = atob(res.id);
      this.createForm();
      //  this.getDealById();
      this.getScopeList();
      
    });

    let oppInfo = localStorage.getItem('deal-info');
    this.dealInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
    let breadCrumbFirst: any = [this.breadcrumb[0]];
    let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
    let breadCrumb = [
      { label: 'Deal Structure', url :'/deal-structure'},
      { label: this.dealInfo?.portfolio?.parent_portfolio?.display_name, url: '/deal-structure', queryParams: {portfolio: this.dealInfo.portfolio?.parent_portfolio?.id}  },
      { label: this.dealInfo?.portfolio?.display_name, url: '/deal-structure',queryParams: {subportfolio: this.dealInfo?.portfolio?.id}  },
      { label: this.dealInfo?.program?.name, url: '/deal-structure',queryParams: {program: this.dealInfo?.program?.id}  },
      { label: this.dealInfo?.deal_name, url: '/deal-structure' }
    ];
    breadCrumbFirst.push(...breadCrumb)

    this.breadCrumService.setBreadcrumb(breadCrumbFirst);


  }
  createForm() {
    this.parameterForm = this.fb.group({
      value: this.fb.array([])
    });
    this.scopeDescForm = new FormGroup({
      scope_desc: new FormControl('', [
        Validators.required,
      ]),
    });
    this.scopeCreateForm = this.fb.array([])
    this.scopeCustomCreateForm = this.fb.array([])
  }

  ngAfterViewInit() {
    // this.tabGroup._selectedIndex = 0;
  }

  get parameterValueFormArray() {
    return this.parameterForm.get('value') as FormArray;
  }

  parameterValueFormArrayControls(i: any): FormGroup {
    return this.parameterValueFormArray.controls[i] as FormGroup;
  }

  getFormValdation(displayName: any, item: any, option: any, req?: any) {
    // const index = this.formScopePipe.transform(item,option,this.parameterValueFormArray);
    // let controls = this.parameterValueFormArrayControls(index);
    // if (req) {
    //   return controls?.get(displayName)?.errors?.required;
    // } else {
    //   return controls?.get(displayName)?.errors;
    // }
    //  this.parameterValueFormArray().controls.for
  }

  createNewFormGroup() {
    return this.fb.group({
    });
  }

  openScope() {
    if (!this.initalLoad) {
      this.initalLoad = true;
      this.sideParam = false;
      this.showUpload = false;
      this.showCustomOnly = false;
      this.newScopeItems = JSON.stringify(this.scopeItems);
      this.newCustomScopeItems = JSON.stringify(this.customScopeItems);
    } else {
      this.sideParam = false;
      this.showCustomOnly = false;
    }
    if(!this.disableFields){
      this.side = true;
    }
  }
  openCustomScope(i: any, option: any) {
    if(!this.disableFields){
      this.side = true;
    }
    this.sideParam = false;
    this.showCustomOnly = false;
    this.showCustomButton = false;
    this.scopeIndex = i;
    this.newScopeItems = JSON.stringify(this.scopeItems);
    this.newCustomScopeItems = JSON.stringify(this.customScopeItems);
  }

  get f() {
    return this.parameterForm.controls;
  }

  async getScopeList() {
    this.spinner.show();
   
     await this.getDealScope();
    this.ps.getOpportunityFormData('opportunity_scopes').subscribe((res: any) => {
      this.spinner.hide();
      
      // this.dataService.scopeData.next(res.records);
      this.scopeList = res.records;
      this.modifiedScopeData(1, this.scopeList);

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  modifiedScopeData(type?: any, scopeList?: any) {
    scopeList.map((i: any) => {
      if (i.field_name == 'scope') {  
        this.allscopeItems = i.field_values;

      }
      if (i.field_name == "scope_parameter") {
        this.parameter = i.field_values;
        
      }
      if (i.field_name == "scope_category") {
        this.scopeCreateForm.reset();
        i.field_values.filter((i: any) => i.is_custom == false).forEach((element: any) => {
          element.category = [];
          this.scopeCreateForm.push(
            this.fb.control(element)
          )
        });

        this.scopeCustomCreateForm.reset();
        i.field_values.filter((i: any) => i.is_custom == true).forEach((element: any) => {
          element.category = [];
          this.scopeCustomCreateForm.push(
            this.fb.control(element)
          )
        });
       
            this.patchapiData();
      
        this.ref.detectChanges();
      }
    })
  }

  patchapiData(){
    
    if(this.patchResponseItem){
    this.dealScopeData =   this.patchResponseItem;
    this.patchResponse = this.dealScopeData;
    // this.ps.setStatusValue(this.dealScopeData?.status);
    this.constructPatchData(this.dealScopeData);
  }
  }



  selectedStepper(e: any) {  
     this.stepper.steps.forEach((step:any,index:number) => {
      if( index == e.selectedIndex){
        this.stepStates[index] = 'edit';
      }else if(!step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'number';
      }else if(step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'done';
      }      
    });
    this.stepperIndex = e.selectedIndex;
    this.showNext = false;
    this.sideParam = false;    
    if(!this.disableFields){
      this.side = true;
    }   
    this.updateScope(this.index); 
    setTimeout(() => {
      this.showNext = true;
    }, 550)
  }

  skipStepper() {
    const tabCount = 2;
    this.tabIndex1 = (this.tabIndex1 + 1) % tabCount;
    this.updateScope();
    this.router.navigate([`deal/`+btoa(this.dealId)+`/toe`]);
    // this.ps.getOppData(this.dealId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`deal/`+btoa(this.dealId)+`/toe`])});
  }

  goToSetup() {
    this.router.navigate([`deal/`+btoa(this.dealId)+`/setup`])
    // this.ps.setValue(0)
    // this.ps.getOppData(this.dealId).subscribe((res)=>{this.ps.setStatusValue(res?.status);this.router.navigate([`deal/`+btoa(this.dealId)+`/setup`])});
  }

  nextScope(stepperVar: any, stepper: any) {  
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    if(!this.disableFields){
      this.side = true;
    }
    stepper.next();
  }

  onExpand(i: any) {
    console.log('open', i);
  }

  onCollapse(i: any) {
    console.log('close', i);
  }

  uploadBar() {
    this.showUpload = true;
    this.showCustomOnly = false;
    this.sideHelp = false;
    this.side = false;
    this.sideParam = false;
  }
  clearCategoryField() {
    this.categoryName = '';
  }

  tabClick(e: any) {
    this.index = e.index;
    if (this.index == 1) {
      this.customTab = true;
      this.side = false;
      this.sideParam = false;
      this.sideHelp = true;
      const noChange = JSON.stringify(this.stepperCategory) === JSON.stringify(this.stepperCategoryInitial);
      if(!noChange || this.hasChange){
        if(this.stepperCategory[this.stepperIndex].selectedItems?.length>0){
          let category = this.stepperCategory[this.stepperIndex].display_name;
          let paramValue = this.parameterForm.get('value').value;
          paramValue = paramValue.map((e:any,index:number)=>{
            e['index'] = index;
            return e;
        });
        let items = paramValue.filter((e:any)=>e.category == category);      
          let valid=true;
          for(let i in items){
            if(this.parameterForm.get('value')?.controls[items[i]?.index].invalid){
              this.toastrService.error("Please fill all the fields");
              valid=false;
              break;
            }
          }
          if(valid){
            this.updateScope();
          }else{            
            const tabCount = 2;
            this.tabIndex1 = (this.tabIndex1 + 1) % tabCount;
          }
        }else{
          this.updateScope();
        }
      }
    }
    if (this.index == 0) {
      e.isActive = true;
      this.customTab = false;
      if(!this.disableFields){
        this.side = true;
      }
      this.showAddCustom = false;
      this.showCustomOnly = false;
      this.showUpload = false;
      const noChange1 = JSON.stringify(this.selectedCategoryItems) === JSON.stringify(this.selectedCategoryItemsInitial);
      if(!noChange1 || this.hasChange)
          this.updateScope();
    }
  }

  clearCustomParam() {
    this.CusParameterName = '';
  }
  

  showCustom() {
    this.newCustomCategory = JSON.stringify(this.customCategory);
    this.showCustomOnly = true;
    this.sideHelp = false;
    this.side = false;
    this.showAddCustom = false;
    this.showCustomButton = false;
    this.showUpload = false;
    this.sideParam = false;
  }

  closeCategory() {
    this.dialog.closeAll();
  }







  goToList() {
    this.router.navigate(['program']);
  }

  getDealById() {
    // this.ps.getOppStatus(this.dealId).subscribe((res: any) => {
      // this.dealScopeData = res;
      this.getScopeList();
      // this.getFile();
      // let breadCrumbFirst: any = [this.breadcrumb[0]];
      // let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      // let breadCrumb = [
      //   { label: this.dealInfo.program, url: '/program' },
      //   { label: this.dealInfo.deal_number, url: '/program' }
      // ];
      // breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      // this.breadCrumService.setBreadcrumb(breadCrumbFirst);

    // }, error => {
    //   throw error;
    // })
  }
 

  getDealScope() {
  //  this.spinner.show();
    return new Promise((resolve, reject) => {
    this.ds.getDealScopeData(this.dealId).subscribe((res: any) => {
        this.tagDetails = {
          id: res.id,
          tag_ids: res.tag_ids
        }
      //  this.spinner.hide();
        this.patchResponseItem = res.deal_scope;

        if(res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed'  || res.status.slug == 'opportunity_cancelled'){
          this.disableFields =true;
          this.side=false;
          this.parameterForm.disable();
          this.parameterForm.get("value").disable();
          this.parameterForm.get("value").controls.forEach((control:any,index:number) => {
            this.parameterForm.get("value").controls[index].disable();
          });
        }
        this.scopeDescForm.get('scope_desc').setValue(res?.scope_description);
       
        this.patchResponseItem?.forEach((el: any) => {
          if(el?.category?.is_custom == true) {
            this.categoryArrayToFilter.push(el?.category.id);
          }
          el?.category?.scope?.forEach((elemnt: any) => {
            this.scopeArrayToFilter.push(elemnt.uuid);
            elemnt.parameter.forEach((p: any) => {
              this.parameterArrayToFilter.push(p.id);
            });
            this.parameterArrayToFilter = _.uniqWith([...this.parameterArrayToFilter], _.isEqual);
          });
        });
        resolve('sdf');
      }, error => {
       // this.spinner.hide();
        reject();
        throw error;
      })
    });
  }

  constructPatchData(res: any) {
    let recommened = res.filter((e:any) => e.category?.is_custom === false)
    recommened.forEach((element: any) => {
      let index = this.scopeCreateForm.value.findIndex((e:any) => e.id === element.category.id)
      this.scopeCreateForm.controls[index].value.category = element.category;
    });
    

  }

  getScope(index: any, scopeIndx: any){
    if(this.allscopeItems){
      let filteringArray = this.scopeCreateForm.controls[index].value.category.scope ? this.scopeCreateForm.controls[index].value.category.scope : [];
      var filteredArray  = filteringArray.length > 0 ? this.allscopeItems.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.id == array_el.id;
        }).length == 0
      }) : this.parameter;

      filteredArray = typeof this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].id === 'string' ? filteredArray.filter((f: any) => f.scope_title.toLowerCase().indexOf(this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].id.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getScopeTitle(scopeId: any){
    if(scopeId){
      return this.allscopeItems.find((item: any) => item.id === scopeId).scope_title;
    }
  }

  searchScope(index: any, scopeIndx: any){
    return this.getScope(index, scopeIndx)
  }

  getScopeDesc(id: any){
    if(this.allscopeItems){
      return id ? this.allscopeItems[this.allscopeItems.findIndex((e:any) => e.id === id)]?.scope_description : ""
    }else{
      return "";
    }
  }

  addScopeItems(index: any){
    if(this.scopeCreateForm.controls[index].value.category.scope == undefined){
      this.scopeCreateForm.controls[index].value.category = {
        scope: []
      }
    }
    this.scopeCreateForm.controls[index].value.category.scope.push({
      id: null,
      title: null,
      description: null,
      uuid: null,
      parameter: []
    })
  }

  removeScope(index: any, i: any){
    this.scopeCreateForm.controls[index].value.category.scope.splice(i, 1);
  }

  getParamterName(parameterId: any) {
    if(parameterId){
      return this.parameter.find((item: any) => item.id === parameterId).name;
    }
  }

  getParameter(index: any, scopeIndx: any, paramIndx: any){
    if(this.parameter){
      let filteringArray = this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter ? this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter : [];
      var filteredArray  = filteringArray.length > 0 ? this.parameter.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.id == array_el.id;
        }).length == 0
      }) : this.parameter;

      filteredArray = typeof this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter[paramIndx].id === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter[paramIndx].id.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  searchParameter(index: any, scopeIndx: any, paramIndx: any){
    return this.getParameter(index, scopeIndx, paramIndx)
  }

  addParameterItems(index: any, scopeIndx: any){
    this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter.push({
      id: null,
      name: null,
      scope_parameter_value: null
    })
  }

  removeParameterItems(index: any, scopeIndx: any, paramIndx: any){
    this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter.splice(paramIndx, 1)
  }

  onInputChanged(value: string): void {
    console.log(value)
  }

  onselectparameter(event: any, index: any, i: any, j: any){
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[j] = Object.assign({}, this.parameter[this.parameter.findIndex((e: any) => e.id == event.option.value)]);
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[j].scope_parameter_value = null;
  }

  onselectscope(event: any, index: any, i: any){
    this.scopeCreateForm.controls[index].value.category.scope[i].uuid = this.allscopeItems[this.allscopeItems.findIndex((e: any) => e.id == event.option.value)].scope_uuid;
  }

  nextSection() {
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.skipStepper();
  }

  updateScope(type?: any) {
 
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.spinner.show();
    let scope_data: any = []

    this.scopeCreateForm.value.forEach((element: any) => {
      let scope: any = [];

      if(element?.category?.scope?.length > 0){
        element.category.scope.forEach((scpe: any) => {
          let parameter: any = [];
  
          if(scpe.parameter.length > 0){
            scpe.parameter.forEach((param: any) => {
              parameter.push({
                scope_parameter: param.id,
                scope_parameter_value: param.scope_parameter_value
              })
            });
          }
          scope.push({
            uuid: scpe.uuid,
            parameter: parameter
          });
        });
      }
      
      scope_data.push({
        scope_category: element.id,
        scope: scope
      })
    }); 


    
    scope_data = {categories:scope_data,description:this.scopeDescForm.controls['scope_desc'].value}
    this.ds.updateDealScope(this.dealId, {scope_data: scope_data}).subscribe((res: any) => {
      this.spinner.hide();
      return true;
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  updateCustomCategory(stepper: any) {
    if(this.parameterForm.invalid){
      this.toastrService.error("Please fill all the fields");
      return;
    }else{
      this.updateScope(1);
    }
  }
  onClickEdit(data:any,modal:any){
    this.scopeData = data;
    this.cusScopeTitle = data.title || data.scope_title;
    this.cusScopeDescription = data.description|| data.scope_description;
    
    this.dialog.open(modal);  
  }
  updateScopeData(){
    this.stepperCategory[this.stepperIndex].selectedItems.filter((ele:any)=>ele.scope_uuid != this.scopeData.scope_uuid) 
  }
  
  clickPrevious(){
    this.router.navigate([`deal/${this.dealId}/setup`])
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "deal"
    }
  }

}

