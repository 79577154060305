<section class="projectOkr">
    <div class="row mb-2">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <p class="fw-bold fs-24 mb-0">Award/Deal</p>
                <div>
                    <button mat-button (click)="add(awardDealPopup)"
                        class="bg-primary text-white" mat-button
                        mat-flat-button>
                        <mat-icon aria-hidden="false" aria-label="material-icons-filled "
                            class="cursor-pointer fs-5">add
                        </mat-icon>
                        Add Award/Deal
                    </button>
                </div>
            </div>
            <p class="m-0 mb-1" style="font-size: 13px;">{{dealInfo?.deal_name}} | {{dealInfo?.deal_number}} | {{dealInfo?.deal_type}} |{{dealInfo?.portfolio?.display_name}} | {{dealInfo?.program?.name}} 
            </p>
           <app-tag [dataObj]="getTagObject(dealInfo?.id)" [tagsIds]="dealInfo?.tag_ids" *ngIf="dealInfo"></app-tag>
        </div>
    </div>
</section>
    <div class="accordion scroll" id="accordionExample" *ngIf="dealVendorDetails.length > 0">
        <ng-container>
            <div class="d-flex justify-content-between"
                *ngFor="let vendor of dealVendorDetails  ; let e = index">
                <div class="col-md-auto accordion-item mb-2 p-0">
                    <div class="accordion-header" id="headingOne{{e}}" >
                        <div class="col-md-auto card-col p-0 position-relative w-100 accordion-button" 
                            [ngClass]="{'collapsed':!vendor['show']}" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapseOne'+e">
                            <div
                                class="row grid m-0 card-grid pb-1 justify-content-between cursor-pointer w-100">
                                <div class="col-md-12">
                                    <div class="pl-2 d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <div class="mr-2">
                                                <span class=" name-1 "
                                                    [ngStyle]="{'backgroundColor': e|getRandomColor}">{{getName(vendor?.vendor?.name)}}</span>
                                            </div>
                                            <div>
                                                <div class="score-count">
                                                    <p class="company-name"
                                                        matTooltip="{{vendor?.vendor?.name}}"
                                                        matTooltipClass="example-tooltip">
                                                        {{vendor?.vendor?.name}}</p>
                                                   </div>
                                                <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapseOne{{e}}" class="accordion-collapse award-tab" [ngClass]="vendor['show']?'show':'collapse'" data-bs-parent="#accordionExample" disabled>
                            <div class="accordion-body p-2">
                                <mat-form-field class="col-6 px-2" appearance="outline">
                                    <mat-label>Project ID</mat-label>
                                    <input type="text" matInput status="basic" [value]="vendor?.project?.project_id" disabled>
                                </mat-form-field>
                                <mat-form-field class="col-6 px-2" appearance="outline">
                                    <mat-label>Project Name</mat-label>
                                    <input type="text" matInput status="basic"  [value]="vendor?.project?.project_name" disabled>
                                </mat-form-field>
                                <div class="row px-2">
                                    <mat-form-field class="py-2 col-6"
                                        appearance="outline">
                                        <mat-icon matPrefix>attach_money</mat-icon>
                                        <mat-label>Project Value</mat-label>
                                        <input type="number" matInput status="basic"  [value]="vendor?.project?.project_value" disabled>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline"
                                    class="w-100 col-6 px-2">
                                    <mat-label>Begin Date</mat-label>
                                    <input matInput [matDatepicker]="picker" readonly
                                         fullWidth disabled
                                        status="basic" (click)="picker.open()"  [value]="vendor?.project?.begin_date">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline"
                                    class="w-100 col-6 px-2">
                                    <mat-label>Expected End Date</mat-label>
                                    <input matInput [matDatepicker]="picker1" readonly
                                         fullWidth disabled
                                        status="basic" (click)="picker1.open()" [value]="vendor?.project?.expected_end_date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <div *ngIf="vendor?.file_name">
                                    <div class="row">
                                        <div class="col-md-12 pl-4 pr-4">
                                        <mat-label>Document</mat-label>
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex">
                                                <div class="mr-2 mt-2">
                                                    <img *ngIf="getFileType(vendor?.file_name) == 'doc' || getFileType(vendor?.file_name) == 'docx'"
                                                        src="./assets/images/word.svg" height="40">
                                                    <img *ngIf="getFileType(vendor?.file_name) == 'pdf'" src="./assets/images/pdf.svg"
                                                        height="40">
                                                    <img *ngIf="getFileType(vendor?.file_name) == 'xls' || getFileType(vendor?.file_name) == 'xlsx'"
                                                        src="./assets/images/excel.svg" height="40">
                                                    <img *ngIf="getFileType(vendor?.file_name) == 'csv'" src="./assets/images/csv.png"
                                                        height="40">
                                                    <mat-icon
                                                        *ngIf="getFileType(vendor?.file_name) !== 'pdf' && getFileType(vendor?.file_name) !== 'doc' && getFileType(vendor?.file_name) !== 'docx' && getFileType(vendor?.file_name) !== 'xls' && getFileType(vendor?.file_name) !== 'xlsx' && getFileType(vendor?.file_name) !== 'csv'"
                                                        mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                                        description</mat-icon>                                    
                                                </div>
                                                <div class="m-2 ms-0">
                                                    {{vendor?.file_name}}
                                                    <!-- <p class="text-muted doc-subhead mb-0">{{templateSize | number : '1.2-2' }}MB</p> -->
                                                </div>
                
                                            </div>
                                            <div class="d-flex">
                                                <div>
                                                    <img (click)="downloadAwardReport(vendor?.id,vendor?.file_name)" class="cursor-pointer px-3"
                                                        src="./assets/images/download-icon-new.svg">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
        </ng-container>
    </div>
    <div *ngIf="dealVendorDetails.length == 0">
        <div class="row">
            <div class="col-md-5 mx-auto mt-5">
                <img src="./assets/images/no-record.png" class="mt-3"
                    height="100%" width="100%">
            </div>
            <div class="col-md-8 mx-auto">

                <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                    {{staticText?.common.no_data_found}}
                </p>
            </div>
        </div>
    </div>
    <ng-template #awardDealPopup>
        <div class="modal-dialog  m-0" style="max-width: 100%;">
            <div class="modal-content" style="border: none;">
                <app-modal-header-common [title]="'Award/Deal'"> </app-modal-header-common>

                <div class="modal-body p-0  p-3 incident-tracker">
                    <form [formGroup]="awardDealForm">
                        <div class="row">
                            <div class="col-lg-12">
                                <mat-form-field class="col-12 px-2" appearance="outline">
                                    <mat-label>OU/GDP</mat-label>
                                    <mat-select formControlName="vendor" >
                                        <ng-container *ngFor="let vendor of vendorList">
                                            <mat-option  [value]="vendor.id" style="font-size: 0.8rem; height: 30px;">
                                                {{vendor?.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-form-field class="col-6 px-2" appearance="outline">
                            <mat-label>Project ID</mat-label>
                            <input type="text" matInput status="basic"
                                formControlName="projectID">
                        </mat-form-field>
                        <mat-form-field class="col-6 px-2" appearance="outline">
                            <mat-label>Project Name</mat-label>
                            <input type="text" matInput status="basic"
                                formControlName="projectName">
                        </mat-form-field>
                        <div class="row px-2">
                            <mat-form-field class="py-2 col-6"
                                appearance="outline">
                                <mat-icon matPrefix>attach_money</mat-icon>
                                <mat-label>Project Value</mat-label>
                                <input type="number" matInput status="basic"
                                    formControlName="projectValue">
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline"
                            class="w-100 col-6 px-2">
                            <mat-label>Begin Date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly
                                formControlName="beginDate" fullWidth
                                status="basic" (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                            class="w-100 col-6 px-2">
                            <mat-label>Expected End Date</mat-label>
                            <input matInput [matDatepicker]="picker1" readonly
                                formControlName="endDate" fullWidth
                                status="basic" (click)="picker1.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker1">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <mat-label class="pl-2">Upload Document*</mat-label>
                        <div class="pl-2" *ngIf="!templatedoc">
                            <div class="row">
                                <div class="col-lg-12 ">
                                    <div class="mt-1 upload">
                                        <div class="w-100 cursor-pointer d-flex justify-content-center"
                                            (click)="fileInput.click()">
                                            <span class="mt-2 ms-2 my-auto secondary"> {{staticText?.common.upload_doc}}</span>
                                        </div>
                                        <input hidden #fileInput type="file" id="file" (change)="onFileSelected($event)">
                                    </div>
                                </div>
                                <p class="text-dark file-status fs-14 mb-0"> {{staticText?.common.doc_ext_maxsize}}</p>
                            </div>
                        </div>
                        <div class="row" *ngIf="templatedoc">
                        <div class="row">
                            <div class="d-flex align-items-center">
                                <div class="d-flex">
                                    <div class="mr-2 mt-2">
                                        <img *ngIf="templatedoc == 'doc' || templatedoc == 'docx'"
                                            src="./assets/images/word.svg" height="40">
                                        <img *ngIf="templatedoc == 'pdf'" src="./assets/images/pdf.svg"
                                            height="40">
                                        <img *ngIf="templatedoc == 'xls' || templatedoc == 'xlsx'"
                                            src="./assets/images/excel.svg" height="40">
                                        <img *ngIf="templatedoc == 'csv'" src="./assets/images/csv.png"
                                            height="40">
                                        <mat-icon
                                            *ngIf="templatedoc !== 'pdf' && templatedoc !== 'doc' && templatedoc !== 'docx' && templatedoc !== 'xls' && templatedoc !== 'xlsx' && templatedoc !== 'csv'"
                                            mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                            description</mat-icon>                                    
                                    </div>
                                    <div class="m-2 ms-0">
                                        {{templateFile}}
                                        <p class="text-muted doc-subhead mb-0">{{templateSize | number : '1.2-2' }}MB</p>
                                    </div>
    
                                </div>
                                <div class="d-flex">
                                    <!-- <div>
                                        <img (click)="downloadFile(i)" class="cursor-pointer px-3"
                                            src="./assets/images/download-icon-new.svg">
                                    </div> -->
                                    <div>
                                        <img (click)="deleteFile(i, delete)" class="cursor-pointer px-3"
                                            src="./assets/images/delete-icon-new.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="d-flex justify-content-between">
                            <!-- <button (click)="saveAudit('addMore')"  mat-flat-button color="primary">Save & Add More
                            </button> -->
                            <button (click)="cancel()" mat-flat-button color="secondary">Close
                            </button>
                        
                            <button (click)="addDealAward()" class="mr-2 ml-2" mat-flat-button color="primary">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>    