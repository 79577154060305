
<div class="row field-adjust" id="custom-height">
    <div *ngIf="!patchResponse" style="height: calc(100vh - 200px);">
        <app-loading></app-loading>
    </div>
    <div class="col-md-12" *ngIf="patchResponse">
        <div class="row">
            <div *ngIf="isRedirectFrom" class="w-auto p-0">
                <h3 class="screen-name" (click)="clickPrevious()">
                    <mat-icon id="chevron_left" aria-hidden="false" aria-label="material-icons-filled "
                        class="cursor-pointer" style="font-size: 35px;">chevron_left
                    </mat-icon>
                </h3>
            </div>
            <div [ngClass]="isRedirectFrom?'col-11':'col-12'" class="title-holder">
                <p id="title" class="fw-bold fs-24 mb-0">{{stepperTitles.title}} <i class="fa fa-edit" (click)="showEdit()" *ngIf="stepperTitles.title == 'Deal Setup'"></i></p>
                <p class="m-0 mb-1" style="font-size: 13px;">{{patchResponse?.deal_name}} | {{patchResponse?.deal_number}} | {{patchResponse?.deal_type}} |{{patchResponse?.portfolio?.display_name}} | {{patchResponse?.program?.name}} 
                </p>
                <app-tag [dataObj]="getTagObject(patchResponse?.id)" [tagsIds]="patchResponse?.tag_ids" *ngIf="patchResponse"></app-tag>
            </div>
        </div>
        <hr>
        <mat-stepper [linear]="patchResponse?.status?.slug=='opportunity_draft'?true:false"
            labelPosition="bottom" [disableRipple]="true" #stepper
            (selectionChange)="selectedStepper($event)" id="setupStep" style="height: 67%;">
            <mat-step label="{{staticText?.rfx?.setup.title}}"
                [stepControl]="setupForm?.disabled?'':setupForm" [state]="stepStates[0]">
                <ng-template matStepLabel>
                    <p id="setup" class="mat-design" matTooltip="{{staticText?.rfx?.setup.title}}">
                        {{staticText?.rfx?.setup.title}}</p>
                </ng-template>
                <div class="set-up custom-height pr-2">
                    <div class="row" >
                        <div class="col-md-12">
                            <form [formGroup]="setupForm">
                                <div class="row mt-2" *ngIf="editShow">
                                    <div class="col-md-12 mb-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label
                                                class="label-design">Deal Name</mat-label>
                                            <input type="text" matInput fullWidth [maxlength]="maxChars"
                                                formControlName="name" id='name'>
                                            <!-- <mat-error *ngIf="(submitted|| f.name.touched) && f.name.errors">
                                                <small class="text-danger"
                                                    *ngIf="f.name.errors.required">{{staticText?.rfx.preview_section.opp_name}}
                                                    is
                                                    required</small>
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label
                                                class="label-design">Deal Type</mat-label>
                                            <input matInput [matAutocomplete]="auto" type="text"
                                                formControlName="deal_type" id='deal_type'>
                                            <mat-icon id="search" matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto="matAutocomplete"
                                                [displayWith]="displayFnName">
                                                <mat-option *ngFor="let option of filterOptionType | async"
                                                    [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error
                                                *ngIf="(submitted|| f.opportunity.touched) && f.opportunity.errors">
                                                <small class="text-danger"
                                                    *ngIf="f.opportunity.errors.required">{{staticText?.rfx.preview_section.opp_type}}
                                                    is
                                                    required</small>
                                                <small class="text-danger"
                                                    *ngIf="f.opportunity.errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label
                                                class="label-design">{{staticText?.rfx.preview_section.portfolio}}</mat-label>
                                            <input matInput [matAutocomplete]="auto1" type="text"
                                                formControlName="portfolio" id='portfolio'>
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto1="matAutocomplete"
                                                [displayWith]="displayFnName">
                                                <mat-option *ngFor="let option of filterOptionPortfolio | async"
                                                    [value]="option"
                                                    (onSelectionChange)="onSelectionChangePortfolio($event)">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error
                                                *ngIf="(submitted|| f.portfolio.touched) && f.portfolio.errors">
                                                <small class="text-danger"
                                                    *ngIf="f.portfolio.errors.required">{{staticText?.rfx.preview_section.portfolio}}
                                                    is
                                                    required</small>
                                                <small class="text-danger"
                                                    *ngIf="f.portfolio.errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label
                                                class="label-design">{{staticText?.rfx.preview_section.sub_portfolio}}</mat-label>
                                            <input matInput [matAutocomplete]="auto2" type="subPortfolio"
                                                formControlName="subPortfolio" id='4'>
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto2="matAutocomplete"
                                                [displayWith]="displayFnName">
                                                <mat-option
                                                    *ngFor="let option of filterOptionSubPortfolio | async"
                                                    [value]="option"
                                                    (onSelectionChange)="onSelectionChangeSubportPortfolio($event)">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error
                                                *ngIf="(submitted|| f.subPortfolio.touched) && f.subPortfolio.errors">
                                                <small class="text-danger"
                                                    *ngIf="f.subPortfolio.errors.required">{{staticText?.rfx.preview_section.sub_portfolio}}
                                                    is required</small>
                                                <small class="text-danger"
                                                    *ngIf="f.subPortfolio.errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label
                                                class="label-design">{{staticText?.rfx.preview_section.program}}</mat-label>
                                            <input matInput [matAutocomplete]="auto5" type="text"
                                                formControlName="program" id='4'>
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto5="matAutocomplete"
                                                [displayWith]="displayFnName">
                                                <mat-option
                                                    *ngFor="let option of filterOptionProgram | async"
                                                    [value]="option"
                                                    >
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error
                                                *ngIf="(submitted|| f.program.touched) && f.program.errors">
                                                <small class="text-danger"
                                                    *ngIf="f.program.errors.required">{{staticText?.rfx.preview_section.program}}
                                                    is required</small>
                                                <small class="text-danger"
                                                    *ngIf="f.program.errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>

                                </div>
                            
                                <!-- <div *ngIf="editShow">
                                    <ng-container *ngFor="let child of subPortFolioChild">
                                        <div class="mb-2">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label class="label-design">{{child.name}}</mat-label>
                                                <input matInput [matAutocomplete]="auto3" type="text"
                                                    formControlName="{{child.key}}" id='childkey'>
                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                <mat-autocomplete #auto3="matAutocomplete"
                                                    [displayWith]="displayFn">
                                                    <mat-option *ngFor="let option of child.arrList"
                                                        [value]="option"
                                                        (onSelectionChange)="onSelectionChangeSubportPortfolioChild($event)">
                                                        {{option.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error
                                                    *ngIf="(submitted|| f[child.key].touched) && f[child.key].errors">
                                                    <small class="text-danger"
                                                        *ngIf="f[child.key].errors.required">{{child.name}}
                                                        is required</small>
                                                    <small class="text-danger"
                                                        *ngIf="f[child.key].errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>-->
                                    <div *ngIf="editShow">
                                    <div class="row mt-2">
                                        <div class="col-md-6 mb-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label
                                                    class="label-design">{{staticText?.rfx.preview_section.owners}}</mat-label>
                                                <input matInput type="text" id='owners' readonly
                                                    formControlName="owners" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> 
                                
                            
                                <div class="row mt-2">
                                    <div class="col-md-12 mb-1" >
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label for="exampleFormControl" class="form-label label-design">
                                                Deal Brief Description
                                            </mat-label>
                                            <textarea id='9' autosize matInput formControlName="deal_brief"
                                                class=" fw-500"
                                                [ngClass]="{ 'is-invalid': submitted && f.deal_brief?.errors }"></textarea>
                                        </mat-form-field>
                                        <!-- <mat-error *ngIf="submitted && f.opportunity_brief?.errors">
                                            <small class="text-danger"
                                                *ngIf="f.opportunity_brief?.errors.required">Deal Brief Description
                                                is
                                                required</small>
                                        </mat-error> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                            <form [formGroup]="profileForm">
                                <div class="row">
                                <div class="col-md-6 mt-2" *ngIf="data.category">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">Deal Category
                                        </mat-label>
                                        <mat-select appearance="filled" class="w-100"
                                            formControlName="category">
                                            <mat-option *ngFor="let category of categoryList"
                                                [value]="category.id">{{category.display_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 my-1" *ngIf="data.classification">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">Deal Classification
                                        </mat-label>
                                        <mat-select appearance="filled" class="w-100"
                                            formControlName="classification">
                                            <mat-option *ngFor="let i of clasificationList" [value]="i.id">
                                                {{i.display_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 my-1" *ngIf="data.triggers">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">Deal Triggers
                                        </mat-label>
                                        <mat-select appearance="filled" class="w-100"
                                            formControlName="triggers">
                                            <mat-option *ngFor="let trigger of triggerList"
                                                [value]="trigger.id">
                                                {{trigger.display_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 my-1" *ngIf="data.work">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="label-design">{{data.disWork}}</mat-label>
                                        <mat-select appearance="filled" class="w-100" formControlName="work" >
                                            <mat-option *ngFor="let work of workList" [value]="work.id">
                                                {{work.display_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <mat-error *ngIf="profSubmitted && p.work?.errors">
                                        <small class="text-danger"
                                            *ngIf="p.work?.errors.required">{{data.disWork}} is
                                            required</small>
                                    </mat-error> -->
                                </div>
                                <div *ngIf="data.fundingStatus">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="fs-16 headLabel">{{data.disfundingStatus}}</label><br>
                                        
                                            <mat-radio-group aria-label="Select an option" class="mx-3"
                                                formControlName="fundingStatus">
                                                <mat-radio-button class="fs-6 fw-500"
                                                    [value]='0'>{{staticText?.rfx.setup.funded}}
                                                </mat-radio-button>
                                                <mat-radio-button class="fs-6  fw-500"
                                                    [value]='1'>{{staticText?.rfx.setup.not_funded}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <!-- <mat-error *ngIf="profSubmitted && p.fundingStatus?.errors">
                                                <small class="text-danger"
                                                    *ngIf="p.fundingStatus?.errors.required">{{data.disfundingStatus}}
                                                    is
                                                    required</small>
                                            </mat-error> -->
                                        </div>
                                        <!-- <div class="col-md-6 my-4">
                                            <mat-checkbox class="example-margin" [checked]="checkedFlag"  (change)="checkAddParameterFlag($event)">Add Custom Parameter</mat-checkbox>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                            </form>
                    </div>
                    <!-- <div *ngIf="checkedFlag" >
                        <form [formGroup]="createParameterForm">
                            <div class="custom-table">
                                <table class="table table-bordered">
                                    <thead style="position: sticky;top: 0; z-index: 1">
                                        <tr>
                                            <th class="fixed-header" scope="col">#</th>
                                            <th class="fixed-header" scope="col">Parameter</th>
                                            <th class="fixed-header" scope="col">Value</th>
                                            <th class="fixed-header text-center col-2" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let option of createParameterForm.controls; let i = index">
                                            <td class="align-middle">{{i+1}}</td>
                                            <td class="align-middle">
                                                <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                    <mat-label></mat-label>
                                                    <input type="text"
                                                        placeholder="Select or Create Parameter" matInput
                                                        [formControl]="option.value.custom_parameter"
                                                        [matAutocomplete]="autoFunctiontest"
                                                        (ngModelChange)="searchFun(i)"
                                                        (input)="onInputChanged($event.target.value)"
                                                        name="custom_parameter">
                                                    <mat-autocomplete #autoFunctiontest="matAutocomplete" (optionSelected)="onOptionSelectionChanged($event,i)"
                                                    [displayWith]="getFunctionName.bind(this)">
                                                        <mat-option *ngFor="let item of getParameterList(i)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                            {{item.display_name}} 
                                                            <span  class="thumbs"> 
                                                                <mat-icon style="transform: scale(0.6);">
                                                                    thumb_up
                                                                </mat-icon> 
                                                            </span>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </td>
                                            <td class="align-middle">
                                                <mat-form-field class="example-full-width" appearance="outline">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput  status="basic" 
                                                     [formControl]="option.value.custom_parameter_value" [ngModelOptions]="{standalone: true}" name="release_name">
                                                </mat-form-field>
                                            </td>
                                            <td class="align-middle text-center">
                                                <div style="display: flex; align-items: center; justify-content: space-around;">
                                                    <span class="cursor-pointer" (click)="removeParameter(i)"
                                                    *ngIf="!disableFields">
                                                        <span class="cursor-pointer"
                                                            (click)="removeFunctionColumn(option)"
                                                            *ngIf="!disableFields">
                                                            <img src="./assets/images/comment-delete.svg"
                                                                style="width:16px;">
                                                        </span>
                                                    </span>
    
                                                    <span class="cursor-pointer" (click)="addFunctionItems(i)"
                                                    *ngIf="!disableFields && createParameterForm.controls.length - 1 === i">
                                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                            class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                        </mat-icon>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="createParameterForm?.controls?.length <= 0">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="w-25">
                                                <a class="add-btton"
                                                    (click)="addFunctionItems()">
                                                    <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                        class="cursor-pointer fs-5">add
                                                    </mat-icon>
                                                    Add Parameter
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div> -->
                </div>
                
                <mat-card-actions class="border-top foot-position mb-0 py-3 footer-align pr-2">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-12 text-right">
                            <button id='setupNext' mat-button class="next-color text-white fs-16"
                                matStepperNext (click)="next('Setup')">
                                <span id="next_btn1234" class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step>
            <mat-step label="{{staticText?.okr_heatmap.krs}}" [state]="stepStates[2]">
                <ng-template matStepLabel>
                    <p class="mat-design" matTooltip="{{staticText?.okr_heatmap.krs}}">
                        {{staticText?.okr_heatmap.krs}} </p>
                </ng-template>
                <div style="height: calc(100vh - 450px); overflow: scroll"> 
                    <app-selection-tree-listing [(items)]="okrArray" selectedOkr="selectedOkr" [disabled]="disableFields" [tree]="'program'" [context]="context"></app-selection-tree-listing>
                </div>
                <mat-card-actions class="border-top mb-0 py-3 px-2 foot-position  footer-align">
                    <div class="row" *ngIf="showNext">
                        <div class="col-md-6">
                            <button id='KeyResultsBack' mat-button
                                class="primary border button-hover text-white fs-16" matStepperPrevious>
                                <mat-icon class="">chevron_left</mat-icon>
                                <span class="m-0 pe-2">
                                    {{staticText?.buttons.back}}
                                </span>
                            </button>
                        </div>
                        <div class="col-md-6 text-right">
                            <button id='KeyResultsNext' mat-button class="fs-16 next-color text-white"
                                matStepperNext (click)="next('Key Results')">
                                <span class="m-0 ps-2">
                                    {{staticText?.buttons?.next_btn}}
                                </span>
                                <mat-icon class="">chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-step>
        </mat-stepper>
    </div>
</div>

