import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-award-deal',
  templateUrl: './award-deal.component.html',
  providers: [DatePipe],
  styleUrls: ['./award-deal.component.scss']
})
export class AwardDealComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  dealInfo:any;
  tagDetails: any;
  dealId:any;
  breadcrumb: any;
  vendorList:any;
  awardDealForm: FormGroup | any;
  templatedoc:any;
  templateSize:any;
  templateFile:any;
  dealVendorDetails:any = [];
  awardColumns:any = ['Sl.No','OU/GDP','Project Id','Project Name','Project Value','Begin Date','Expected End Date','Document']
  constructor(
    private breadCrumService: BreadcrumbService,
    private route: ActivatedRoute,
    private ds: DealStructureService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) { 
    this.awardDealForm = this.fb.group({
      vendor:['',Validators.required],
      projectID:['',Validators.required],
      projectName:['',Validators.required],
      projectValue:['',Validators.required],
      beginDate:['',Validators.required],
      endDate:['',Validators.required],
      file:['']
    });
  }

  ngOnInit(): void {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.dealId = atob(res.id)
      // this.getDealById();
    })
    let oppInfo = localStorage.getItem('deal-info');
    this.dealInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
    let breadCrumbFirst: any = [this.breadcrumb[0]];
    let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
    let breadCrumb = [
      { label: this.dealInfo?.portfolio?.parent_portfolio?.display_name, url: '/deal-structure', queryParams: {portfolio: this.dealInfo.portfolio?.parent_portfolio?.id}  },
      { label: this.dealInfo?.portfolio?.display_name, url: '/deal-structure',queryParams: {subportfolio: this.dealInfo?.portfolio?.id}  },
      { label: this.dealInfo?.program?.name, url: '/deal-structure',queryParams: {program: this.dealInfo?.program?.id}  },
      { label: this.dealInfo?.deal_name, url: '/deal-structure' }
    ];
    breadCrumbFirst.push(...breadCrumb)

    this.breadCrumService.setBreadcrumb(breadCrumbFirst);
    this.getDealAwardVendor();
    this.getVendor();

  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "deal"
    }
  }

  async getVendor(){
   await this.ds.getVendor().subscribe((res:any)=>{
      this.vendorList = res;
    })
  }
  getName(name: any) {
    if(!name){
return "";
    }
    return name.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }

  add(modal:any){
    this.dialog.open(modal, {
      width: 'auto',
      maxWidth: '500px',
      minHeight: '67vh',
    });
  }

  deleteFile(i: any, content: any) {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.awardDealForm.get('file').setValue('');
  }

  onFileSelected(e:any){
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (inputElement.files[0].size > 5000000) {
        this.toastrService.error('Please upload less than 5MB');
        return;
      }
      this.awardDealForm.get('file').setValue(file);
      this.templateFile = inputElement.files[0].name;
      this.templateSize= inputElement.files[0].size / 1000000;
      const ext = inputElement.files[0].name.substring(inputElement.files[0].name.lastIndexOf('.') + 1)
      this.templatedoc = ext?.toLowerCase()
    }
  }
  emptyFile() {
    this.templatedoc = '';
    this.templateSize = '';
    this.templateFile = '';
    this.awardDealForm.get('file').setValue('');
  }
  addDealAward(){
    this.awardDealForm.markAllAsTouched();
    if (this.awardDealForm.invalid ) {
       return;
    }
    this.awardDealForm.value.beginDate = this.datepipe.transform(this.awardDealForm.value.beginDate,"yyyy-MM-dd");
    this.awardDealForm.value.endDate = this.datepipe.transform(this.awardDealForm.value.endDate,"yyyy-MM-dd");
    const formData = new FormData();

    formData.append('vendor_id',  this.awardDealForm.get('vendor').value);
    formData.append('deal_id',  this.dealId);
    formData.append('project_id', this.awardDealForm.get('projectID').value);
    formData.append('project_name', this.awardDealForm.get('projectName').value);
    formData.append('project_value', this.awardDealForm.get('projectValue').value);
    formData.append('begin_date', this.awardDealForm.value.beginDate);
    if(this.awardDealForm.get('file').value){
      formData.append('document', this.awardDealForm.get('file').value ? this.awardDealForm.get('file').value : null);
    }
    formData.append('expected_end_date', this.awardDealForm.value.endDate);
    
    this.spinner.show();
    this.ds.postAwardDeal(formData).subscribe((res: any) => {
      if(res){
        this.dealVendorDetails.push(res);
      }
      this.toastrService.success('Award Deal created successfully');
      this.dialog.closeAll();
      this.emptyFile();
      this.awardDealForm.reset();

      this.spinner.hide();;

    }, (error: any) => {
      this.spinner.hide();
      if(error.error.errors.project_id[0]){
        this.toastrService.error(error.error.errors.project_id[0]);
      }
      throw error;
    });
    
  }

  cancel(){
    this.dialog.closeAll();
  }

  getDealAwardVendor(){
    this.ds.getDealAwardVendor(this.dealId).subscribe((res:any)=>{
      this.dealVendorDetails = res;
    }, (error: any) => {
      this.spinner.hide();
      console.log(error.errors);
      throw error;
    });
  }

  downloadAwardReport(id:any,fileName:any){
    this.spinner.show();
    this.ds.downloadAwardFile(id).subscribe((res: any) => {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(res);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.toastrService.success('File downloaded successfully');
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      throw error;
    })
  }

  getFileType(file:any){
    const ext = file.substring(file.lastIndexOf('.') + 1)
    return  ext?.toLowerCase()
  }
}
