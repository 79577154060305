<section class="projectOkr">
    <div class="row mb-2">
        <div class="col-12">
            <p class="fw-bold fs-24 mb-0">{{staticText?.opportunity.toe_full_form}}</p>
            <p class="m-0 mb-1" style="font-size: 13px;">{{dealInfo?.deal_name}} | {{dealInfo?.deal_number}} | {{dealInfo?.deal_type}} |{{dealInfo?.portfolio?.display_name}} | {{dealInfo?.program?.name}} 
            </p>
           <app-tag [dataObj]="getTagObject(tagDetails?.id)" [tagsIds]="dealInfo.tagDetails" *ngIf="dealInfo.tagDetails"></app-tag>
        </div>
    </div>

    <div class="row">
        <div class="pr-0" [ngClass]="activeTab == staticText?.delivery.toe.setup? 'col-12':'col-12'">
            <div style="height: calc(100vh - 340px); overflow: scroll">
                <app-selection-tree-listing [(items)]="termsArray" selectedOkr="selectedOkr" [disabled]="false" [tree]="'program'" [context]="context" [fromPage]="'toe'"></app-selection-tree-listing>
            </div>

        </div>
    </div>
    <div class="col-md-12 mx-auto col-12">
        <div class="row border-top">
            <div class="col-md-6 d-flex justify-content-start">
                <button id="previous" mat-button mat-stroked-button color="primary"
                    (click)="previous()">
                    <mat-icon class="">chevron_left</mat-icon>
                    <span id="prev_section" class="m-0 pe-2">
                        {{staticText?.rfx?.prev_section}}
                    </span>
                </button>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <button id="updateEvaluation" mat-button mat-flat-button color="primary" class="bg-primary text-white"
                    (click)="clickNext()">
                    <span id="next_section" class="m-0 ps-2">
                        {{staticText?.rfx?.next_section}}
                    </span>
                    <mat-icon class="">chevron_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
  </section>