
import { Component, OnInit, ViewEncapsulation, ViewChild, Pipe } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/shared/services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ProgramService } from 'src/app/shared/services/program.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';
declare var $: any;

@Component({
  selector: 'app-toe',
  templateUrl: './toe.component.html',
  styleUrls: ['./toe.component.scss']
})
export class ToeComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  toeForm!: FormGroup | any;
  activeTab = this.staticText?.delivery.toe.setup;

  projectToEData: any[] = [];
  projectSelectedToEs:any[] =[];

  selectedFile: any = {};
  dealId: any;
  showToEPane: boolean = false;
  toePage = 1;
  subCategoryData: any = {};

  activeChildTab = '';
  selectedToEs: any = [];
  selectedToeData: any = {};
  selectedSetupChildIndex: number = 0;
  maxNumberOfTabs: number = 30;

  selectedSetupSubChildIndex: number = 0;

  toggletacc = new Map<string, boolean>();

  isToeDataAvailable:boolean=false;
  apiResponseReceived:boolean=false;
  dealInfo: any;
  tagDetails: any;

  termsArray: any;
  selectedTerms: any;
  context: any;

  breadcrumb: any;

  teamList: any = [];
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(
    private programService: ProgramService,
    private dealStructureService: DealStructureService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private breadCrumService: BreadcrumbService) {
      this.context = {
        componentParent: this
      }
  }

  ngAfterViewInit() {
    let that = this;
    var thead = $(this).find('thead');
    var tbody = $(this).find('tbody');

    tbody.hide();
    thead.click(function () {
      tbody.slideToggle();
    })
  }

  ngOnInit(): void {
    this.getAllMandatoryData();
    let oppInfo = localStorage.getItem('deal-info');
    this.dealInfo = typeof(oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
console.log(this.dealInfo);
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
  }

  getAllMandatoryData() {
    this.getDealId();
    // this.getToE();
    this.gettoe();
  }
  getDealId() {
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.dealId = atob(res.id)

    });
  }
  toggleAcc(id: any) {
    if (this.toggletacc.get(id)) {
      this.toggletacc.set(id, false);

    } else {
      this.toggletacc.set(id, true);

    }

  }

  gettoe(){
    this.spinner.show();
    this.dealStructureService.getDealToeData(this.dealId).subscribe((getTerm:any) => {
      
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: 'Deal Structure', url :'/deal-structure'},
        { label: this.dealInfo?.portfolio?.parent_portfolio?.display_name, url: '/deal-structure', queryParams: {portfolio: this.dealInfo.portfolio?.parent_portfolio?.id}  },
        { label: this.dealInfo?.portfolio?.display_name, url: '/deal-structure',queryParams: {subportfolio: this.dealInfo?.portfolio?.id}  },
        { label: this.dealInfo?.program?.name, url: '/deal-structure',queryParams: {program: this.dealInfo?.program?.id}  },
        { label: this.dealInfo?.deal_name, url: '/deal-structure' }
      ];
      breadCrumbFirst.push(...breadCrumb)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);
  
      getTerm.terms_hierarchy.forEach((category: any) => {
        category.level = "category";
        if(category.subcategories.length > 0){
          category.children = category.subcategories;
          category.children.forEach((sub: any) => {
            sub.level = "subcategory";
            sub.category_id = category.id;
            if(sub.terms.length > 0){
              sub.children = sub.terms;
              sub.children.forEach((term: any) => {
                term.level = "term";
                term.name = term.title;
                term.subcategory_id = sub.id;
                term.category_id = category.id;
                getTerm.selected_term_ids.forEach((element:any) => {
                  if(element.term_id == term.id){
                    term.comments = element?.comments ? element?.comments : '';
                  }
                });
                if(getTerm.selected_term_ids.findIndex((e: any) => e.term_id == term.id) > -1){
                  term.selected = true;
                }else{
                  term.selected = false;
                }
              })

              if(sub.children.filter((e: any) => e.selected === true).length == sub.children.length){
                sub.selected = true;
                sub.selection_type = 'all';
              }else if(sub.children.filter((e: any) => e.selected === true).length > 0){
                sub.selected = true;
                sub.selection_type = 'intermediate';
              }else{
                sub.selected = false;
                sub.selection_type = 'none';
              }
            }
          });

          if(category.children.filter((e: any) => e.selection_type === 'all').length == category.children.length){
            category.selected = true;
            category.selection_type = 'all';
          }else if(category.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || category.children.filter((e: any) => e.selection_type === 'all').length > 0){
            category.selected = true;
            category.selection_type = 'intermediate';
          }else{
            category.selected = false;
            category.selection_type = 'none';
          }
        }
      });
      this.termsArray = getTerm.terms_hierarchy;
      this.selectedTerms = getTerm.selected_term_ids;
      this.spinner.hide();      
    })
  }

  collapse(item: any){
    if(item.level == 'category'){
        this.termsArray.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }else if(item.level == 'subcategory'){
        let termIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);

        if(this.termsArray[termIndex].children.length > 0){
          this.termsArray[termIndex].children.forEach((element: any) => {
            if(element.id != item.id) element.expanded = false;
          });
        }
      }
    }

    selectGoals(item: any){
      if(item.level == 'category'){
        this.termsArray.forEach((cat: any) => {
          if(cat.id == item.id) {
            cat.selected = item.selected;
            cat.selection_type = item.selected ? 'all' : 'none';

            cat.children.forEach((sub: any) => {
              sub.selected = item.selected;
              sub.selection_type = item.selected ? 'all' : 'none';

              sub.children.forEach((term: any) => {
                term.selected = item.selected;
                term.selection_type = item.selected ? 'all' : 'none';
              });
            });
          }
        });
      }else if(item.level == 'subcategory'){
        let subIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);

        if(this.termsArray[subIndex].children.length > 0){
          this.termsArray[subIndex].children.forEach((sub: any) => {
            if(sub.id == item.id) {
              sub.selected = item.selected;
              sub.selection_type = item.selected ? 'all' : 'none';

              sub.children.forEach((term: any) => {
                term.selected = item.selected;
                term.selection_type = item.selected ? 'all' : 'none';
              });
            }
          });

          if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length == this.termsArray[subIndex].children.length){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].selected = false;
            this.termsArray[subIndex].selection_type = 'none';
          }
        }    
      }else if(item.level == 'term'){
        let subIndex = this.termsArray.findIndex((e: any) => e.id == item.category_id);
        let termIndex = this.termsArray[subIndex].children.findIndex((e: any) => e.id == item.subcategory_id);

        if(this.termsArray[subIndex].children[termIndex].children.length > 0){
          this.termsArray[subIndex].children[termIndex].children.forEach((term: any) => {
            if(term.id == item.id) {
              term.selected = item.selected;
              term.selection_type = item.selected ? 'all' : 'none';
              term.comments = item?.comments ? item?.comments : ''

            }
          });
          

          if(this.termsArray[subIndex].children[termIndex].children.filter((e: any) => e.selected === true).length == this.termsArray[subIndex].children[termIndex].children.length){
            this.termsArray[subIndex].children[termIndex].selected = true;
            this.termsArray[subIndex].children[termIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children[termIndex].children.filter((e: any) => e.selected === true).length > 0){
            this.termsArray[subIndex].children[termIndex].selected = true;
            this.termsArray[subIndex].children[termIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].children[termIndex].selected = false;
            this.termsArray[subIndex].children[termIndex].selection_type = 'none';
          }

          if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length == this.termsArray[subIndex].children.length){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'all';
          }else if(this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.termsArray[subIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.termsArray[subIndex].selected = true;
            this.termsArray[subIndex].selection_type = 'intermediate';
          }else{
            this.termsArray[subIndex].selected = false;
            this.termsArray[subIndex].selection_type = 'none';
          }
        }
      }
    }

  saveSelection(){
    let tArray: any = [];

    this.termsArray.forEach((category: any) => {
      category?.children?.forEach((sub: any) => {
        sub?.children?.forEach((term: any) => {
          if(term.selected){
            tArray.push({term_id:term?.id,comments:term?.comments ? term?.comments : '' });
          }
        });
        
      });
    });

    let postdata = {
      deal_id: this.dealId,
      terms: tArray
    }

    this.dealStructureService.selectDealToe(postdata).subscribe((getTerms:any) => {
         
    })
  }



  onSelectSubCategory(evt: any, index: number) {
    if (evt.target.checked) {
      this.projectToEData[this.selectedSetupChildIndex]?.term_sub_category?.[index]?.toe?.map((e: any) => e.is_linked_toe = true);
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  previous() { 
    this.router.navigate([`deal/`+btoa(this.dealId)+`/scope`]);
  }

  clickNext() { 
    this.saveSelection();
    this.router.navigate([`deal/`+btoa(this.dealId)+`/award-deal`]);
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }

  reload(){
    this.ngOnInit();
  }

}
