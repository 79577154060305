import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetupComponent } from './setup/setup.component';
import { IndexComponent } from './index/index.component';
import { ScopeComponent } from './scope/scope.component';
import { ToeComponent } from './toe/toe.component';
import { AwardDealComponent } from './award-deal/award-deal.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
      {
        path: 'setup',
        component: SetupComponent,//data: { breadcrumb: 'Opportunity' } 
      },
      {
        path: 'scope',
        component: ScopeComponent,//data: { breadcrumb: 'Opportunity' } 
      },
      {
        path: 'toe',
        component: ToeComponent,//data: { breadcrumb: 'Opportunity' } 
      },
      {
        path: 'award-deal',
        component: AwardDealComponent,
      }
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DealStructureRoutingModule { }
