<div class="row mb-3">
    <div class="col-md-12">

        <div class="row">
            <div *ngIf="isRedirectFrom" class="w-auto p-0">
                <h3 class="screen-name" (click)="clickPrevious()">
                    <mat-icon id="chevron_left" aria-hidden="false" aria-label="material-icons-filled "
                        class="cursor-pointer" style="font-size: 35px;">chevron_left
                    </mat-icon>
                </h3>
            </div>
            <div [ngClass]="isRedirectFrom?'col-8':'col-9'">
                <p class="heading-color fw-500 mb-0">Deal Scope</p>
                <p class="m-0 mb-1" style="font-size: 13px;">{{dealInfo?.deal_name}} | {{dealInfo?.deal_number}} | {{dealInfo?.deal_type}} |{{dealInfo?.portfolio?.display_name}} | {{dealInfo?.program?.name}} 
                </p>
                <app-tag [dataObj]="getTagObject(tagDetails?.id)" [tagsIds]="tagDetails?.tag_ids" *ngIf="tagDetails"></app-tag>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <form [formGroup]="scopeDescForm">
            <div class="row mt-2 field-adjust">
                <div class="col-md-12 mb-1" >
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label for="exampleFormControl" class="form-label label-design">
                            Brief Description
                        </mat-label>
                        <textarea id='9' autosize matInput formControlName="scope_desc"
                            class=" fw-500"
                            [ngClass]="{ 'is-invalid': submitted && f.scope_desc?.errors }"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="submitted && f.scope_desc?.errors">
                        <small class="text-danger"
                            *ngIf="f.scope_desc?.errors.required">Brief Description
                            is
                            required</small>
                    </mat-error>
                </div>
            </div>
        </form>
        
        <form [formGroup]="scopeCreateForm">
                    <mat-stepper linear labelPosition="bottom" [disableRipple]="true" #stepper class="my-1 scope"
                        id="scopeStep" (selectionChange)="selectedStepper($event)" style="height: 67%;">
                        <mat-step *ngFor="let stepperVar of scopeCreateForm.value;let index=index;" [state]="stepStates[index]">
                            <ng-template matStepLabel>
                                <p class="mat-design" matTooltip="{{stepperVar?.display_name}}"
                                    matTooltipClass="example-tooltip">
                                    {{stepperVar.display_name}}</p>
                            </ng-template>
                                          
                            <div class="scope-height" [ngClass]="{'scope-height': stepperVar?.selectedItems?.length > 0 }">
                                <div class="row">
                                    <div class="col-md-12 col-12 pt-1">
                                        <div class="bg-light" *ngIf="stepperVar?.selectedItems?.length != 0">
                                            <p class="py-2 ps-3 fs-16 fw-500 title-color mb-1" style="letter-spacing: 1px;">
                                                {{stepperVar?.display_name}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-table table-responsive mb-3" >
                                    <table class="table table-bordered" >
                                        <thead>
                                            <tr style="position: sticky; top: 0; z-index: 9">
                                                <th scope="col">#</th>
                                                <th scope="col" class="w-75">Scope</th>
                                                <th class="text-center" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let scope of stepperVar.category.scope; let i = index">
                                                <td>{{i+1}}</td>
                                                <td class="w-75">
                                                    <div class="wprr">
                                                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                            <mat-label hidden></mat-label>
                                                            <input type="text"
                                                                placeholder="Parameter" matInput
                                                                [(ngModel)]="scope.id" [ngModelOptions]="{standalone: true}"
                                                                [matAutocomplete]="autoScope"
                                                                (ngModelChange)="searchScope(index, i)" attr.name="scope_name{{i}}">
                                                            <mat-autocomplete #autoScope="matAutocomplete"
                                                            [displayWith]="getScopeTitle.bind(this)" (optionSelected)="onselectscope($event, index, i)">
                                                                <mat-option *ngFor="let item of getScope(index, i)" [value]="item.id" style="font-size: 0.8rem; margin-left: 9px; height: unset; line-height: 1.5;">
                                                                    {{item.scope_title}} 
                                                                    <span style="font-size: 11px;font-style: italic;">
                                                                        <br> {{item.scope_description}} 
                                                                    </span>
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>
                                                    <span class="spame" style="display: inline-block" *ngIf="getScopeDesc(scope.id)">{{getScopeDesc(scope.id)}} <br></span>
                                                    <table class="table table-bordered mt-2" style="z-index:0">
                                                        <thead style="z-index:0">
                                                            <tr style="z-index:0">
                                                                <th scope="col" style="background: #0bb797;">#</th>
                                                                <th scope="col" class="w-50" style="background: #0bb797;">Parameter</th>
                                                                <th scope="col" class="w-50" style="background: #0bb797;">Value</th>
                                                                <th class="text-center col-2" scope="col" style="background: #0bb797;">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let pmtr of scope.parameter; let j = index">
                                                                <td class="align-middle">{{j+1}}</td>
                                                                <td class="align-middle">
                                                                    <mat-form-field class="example-full-width" style="width: 100%" appearance="outline">
                                                                        <mat-label></mat-label>
                                                                        <input type="text"
                                                                            placeholder="Parameter" matInput
                                                                            [(ngModel)]="pmtr.id" [ngModelOptions]="{standalone: true}"
                                                                            [matAutocomplete]="autoParameter"
                                                                            (ngModelChange)="searchParameter(index, i, j)" attr.name="parameter_name{{i}}{{j}}">
                                                                        <mat-autocomplete #autoParameter="matAutocomplete"
                                                                        [displayWith]="getParamterName.bind(this)" (optionSelected)="onselectparameter($event, index, i, j)">
                                                                            <mat-option *ngFor="let item of getParameter(index, i, j)" [value]="item.id" style="font-size: 0.8rem; height: 30px;">
                                                                                {{item.name}} 
                                                                            </mat-option>
                                                                        </mat-autocomplete>
                                                                    </mat-form-field>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <mat-form-field appearance="outline"
                                                                            class="w-100 py-0"
                                                                            *ngIf="pmtr.display_name != 'Date'">
                                                                            <mat-label class="label-design"></mat-label>
                                                                            <input type="text" matInput fullWidth status="basic" 
                                                                            [(ngModel)]="pmtr.scope_parameter_value" attr.name="parameter_value{{i}}{{j}}" [ngModelOptions]="{standalone: true}">
                                                                        </mat-form-field>

                                                                        <mat-form-field appearance="outline" class="w-100 py-0"
                                                                            *ngIf="pmtr.display_name == 'Date'">
                                                                            <mat-label class="label-design"></mat-label>
                                                                            <input matInput [matDatepicker]="picker"
                                                                                readonly
                                                                                [(ngModel)]="pmtr.scope_parameter_value"
                                                                                fullWidth status="basic" (click)="picker.open()" attr.name="parameter_value{{i}}{{j}}" [ngModelOptions]="{standalone: true}">
                                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                                            </mat-datepicker-toggle>
                                                                            <mat-datepicker #picker>
                                                                            </mat-datepicker>
                                                                        </mat-form-field>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div style="display: flex; align-items: center; justify-content: space-around;">
                                                                        <span class="cursor-pointer" (click)="removeParameterItems(index, i, j)"
                                                                        *ngIf="!disableFields">
                                                                            <span class="cursor-pointer">
                                                                                <img src="./assets/images/comment-delete.svg"
                                                                                    style="width:16px;">
                                                                            </span>
                                                                        </span>
                                                                        <span class="cursor-pointer" (click)="addParameterItems(index, i, j)"
                                                                        *ngIf="!disableFields && scope.parameter.length - 1 === j">
                                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                                class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                                            </mat-icon>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="scope?.parameter?.length <= 0">
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="w-50">
                                                                    <a class="add-btton"
                                                                        (click)="addParameterItems(index, i)" *ngIf="!disableFields">
                                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                            class="cursor-pointer fs-5">add
                                                                        </mat-icon>
                                                                        Add Parameter
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <div style="display: flex; align-items: center; justify-content: space-around;">
                                                        <span class="cursor-pointer" (click)="removeScope(index, i)"
                                                        *ngIf="!disableFields">
                                                            <span class="cursor-pointer">
                                                                <img src="./assets/images/comment-delete.svg"
                                                                    style="width:16px;">
                                                            </span>
                                                        </span>
        
                                                        <span class="cursor-pointer" (click)="addScopeItems(index)"
                                                        *ngIf="!disableFields && stepperVar.category.scope.length - 1 === i">
                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                            </mat-icon>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="stepperVar?.category?.scope?.length <= 0">
                                                <td></td>
                                                <td></td>
                                                <td class="w-50">
                                                    <a class="add-btton"
                                                        (click)="addScopeItems(index)" *ngIf="!disableFields">
                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                            class="cursor-pointer fs-5">add
                                                        </mat-icon>
                                                        Add Scope
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                    
                                    </div>
                                </div>
                            </div>
                            <mat-card-actions class="border-top footer-align mb-0 py-3">
                                <div class="row" *ngIf="showNext">
                                    <div class="col-md-6">
                                        <button attr.id="buttons_back{{stepperIndex}}" mat-button class="primary border button-hover text-white"
                                            *ngIf="stepperIndex != 0" matStepperPrevious>
                                            <mat-icon id="buttons_back" class="">chevron_left</mat-icon>
                                            <span id="buttons_back_new" class="m-0 pe-2">
                                                {{staticText?.buttons.back}}
                                            </span>
                                        </button>
                                        <button attr.id="prev_section{{stepperIndex}}" mat-button class="primary border button-hover text-white"
                                            *ngIf="stepperIndex == 0" (click)="goToSetup()">
                                            <mat-icon class="">chevron_left</mat-icon>
                                            <span id="prev_section11" class="m-0 pe-2">
                                                {{staticText?.rfx.prev_section}}
                                            </span>
                                        </button>
                                    </div>
                                    <div class="col-md-6 text-right">

                                        <button attr.id="prev_section{{stepperIndex}}" mat-button class="text-white" (click)="skipStepper()">
                                            <span id="skip" class="skip"> {{staticText?.rfx.scope.skip}}</span>

                                        </button>
                                        <button attr.id="next_btn{{stepperIndex}}" mat-button class="next-color text-white"
                                            *ngIf="scopeCreateForm.value.length -1 !== stepperIndex"
                                            (click)="nextScope(stepperVar,stepper)">
                                            <span id="m-0_Sps-2" class="m-0 ps-2">
                                                {{staticText?.buttons.next_btn}}
                                            </span>
                                            <mat-icon class="">chevron_right</mat-icon>
                                        </button>
                                        <button attr.id="rfx_next_section{{stepperIndex}}" mat-button class="bg-primary text-white"
                                            (click)="nextSection()" *ngIf="scopeCreateForm.value.length -1 == stepperIndex">
                                            <span id="next_section11323" class="m-0 ps-2">
                                                {{staticText?.rfx.next_section}}
                                            </span>
                                            <mat-icon class="">chevron_right</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </mat-card-actions>
                        </mat-step>
                    </mat-stepper>
                </form>
    </div>
</div>
<div class="col-md-4 col-12 p-0">
</div>
<!-- </div> -->
<ng-template #removeCategory>
    <div class="modal-dialog m-0">
        <div class="modal-content">

            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>

            <div class="modal-body">
                <p class=" fs-5 py-3 text-center primary m-0 fw-normal">{{staticText?.rfx.scope.rm_msg}} "{{name}}"?</p>
                <p class="m-0 text-center">{{staticText?.rfx.scope.rm_content}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button id="cancel_btn1" mat-button mat-dialog-close (click)="closeCategory()"
                            class="text-blue cancel-cat">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button id="yes_remove_btn" mat-button [disabled]="disableFields" mat-dialog-close
                            (click)="deleteCategory()"
                            class="text-white bg-red">{{staticText?.rfx.scope.yes_remove_btn}}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #editScope>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="'Edit Scope'"> </app-modal-header-common>

            <div class="modal-body field-adjust">
                <mat-form-field class="py-2 w-100" appearance="outline">
                    <mat-label>Scope Name</mat-label>
                    <input type="text" matInput fullWidth status="basic" name="cusScopeTitle"
                        [(ngModel)]="cusScopeTitle">
                    <button id="cusScopeTitle" mat-button mat-icon-button matSuffix (click)="cusScopeTitle=''"
                        *ngIf="cusScopeTitle">
                        <mat-icon class="fs-5">close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="py-2 w-100" appearance="outline">
                    <mat-label>Scope description</mat-label>
                    <input type="text" matInput fullWidth status="basic" name="cusScopeDescription"
                        [(ngModel)]="cusScopeDescription">
                    <button id="cusScopeDescription" mat-button mat-icon-button matSuffix
                        (click)="cusScopeDescription=''" *ngIf="cusScopeDescription">
                        <mat-icon class="fs-5">close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col">
                        <button id="closeDialog" mat-button mat-dialog-close class="text-blue cancel-cat"
                            (click)="closeDialog()">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col">
                        <button id="updateScopeData" (click)="updateScopeData()" [disabled]="disableFields" mat-button
                            class="text-white bg-red">Create Custom Scope</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>