    <div class="px-0 py-0">
        <app-bread-crumb></app-bread-crumb>
    </div>
    <div class="row pt-4">
        <div class="col-sm-2">
            <div class="single-left-pane">
                <app-deal-left-side-menu></app-deal-left-side-menu>
            </div>
        </div>
        <div class="col-sm-7">
            <div class="single-center-pane">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="single-right-pane">
                <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
                <h6>Insights & Engagement Zone</h6>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style="font-size: 13px">
                            Opportunity Heat Map by Skill Type 
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="comingSoon text-center">
                                <img src="assets/images/analytics_bg.png">
                                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="font-size: 13px">
                            Opportunity Heat Map by Tags
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="comingSoon text-center">
                                <img src="assets/images/analytics_bg.png">
                                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>

