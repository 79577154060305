import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProgramService } from 'src/app/shared/services/program.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { map, startWith, tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/services/data.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ColumnMode } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { LodashService } from 'src/app/shared/services/lodash.service';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { MatStepper } from '@angular/material/stepper';
import { Observable, combineLatest } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { CustomPropertiesComponent } from 'src/app/pages/rfx/opportunity/custom-properties/custom-properties.component';
import { DealStructureService } from 'src/app/shared/services/deal-structure.service';
var _ = require('lodash');
export interface nameList {
  name: string;
}

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @ViewChild("scrollTO") MyProp: ElementRef;
  profileForm: FormBuilder | any;
  setupForm: FormGroup | any;
  goalForm: FormGroup | any;
  keyForm: FormGroup | any;
  showAddField: boolean | any;
  @ViewChild('stepper') stepper: MatStepper|any;

  id: any[] = [];
  items: any[] = [];
  nameList: any[] = [];
  userList: any[] = [];
  chipsList: any[] = [];
  keyRecords: any[] = [];
  goalTitles: any[] = [];
  keyResult: any[] = [];
  customItems: any[] = [];
  customRecords: any[] = [];
  selectedItems: any[] = [];
  parameterList: any[] = [];
  // Slave key result for right pane which is list component
  slaveKeyResult: any[] = [];
  slaveKeyResultClone: any[] = [];
  // Master key result for centerpane
  masterKeyResult: any[] = [];
  masterKeyResultInitial: any[] = [];
  selectedKeyItems: any[] = [];
  customNewKeyItems: any[] = [];
  customNewItems: any[] = [];
  selectedVendorItems: any[] = [];
  selectedVendorItemsInitial:any[]=[];
  selectedTimelineItems: any[] = [];
  selectedFunctionItems: any[] = [];
  constantKeyResults: any[] = [];
  customTimeline: any[] = [];
  teamArrayToFilter: any[] = [];

  disableFields=false;

  newCustomItems: any;
  keyDescriptions: any;
  disDescription: any;
  updateItems: any;
  newItems: any;
  cutomupdateItems: any;
  dealId: any;
  parameterName: any;

  setup_data: any = {};

  textContents = {
    searchText: 'Parameter searched is not found in the list'
  }
  stepperTitles = {
    title: 'Deal Setup',
    buttonName: 'Parameter'
  }


  tips = true
  sideHelp = true;
  showPara = true;
  removable = true;
  addOnBlur = true;
  selectable = true;
  side = false;
  side1 = false;
  keyExist = false;
  keySubmit = false;
  initalLoad = false;
  goalExist = false;
  isLinear = false;
  showNext = false;
  errorMsg = false;
  search = false;
  newTimeline = false;
  submitted = false;
  profSubmitted = false;
  customKeypanel = false;
  isMemberSelected = false;
  timeLineSubmitted = false;
  showSecondaryKey = false;
  customParameter = false;
  editShow = false;

  keyData: any;
  data: any;
  teamList: any;
  username: any;
  goalPayload: any;
  vendorList: any;
  permission: any;
  functionList: any;
  functionName: any;
  newGoalItems: any;
  updateKeyItems: any;
  filterOptionTitle: any;
  newFunctionItems: any;
  newCustomFunctionItems: any;
  customFunctionList: any;
  unFilteredTeamList: any;
  selectedMember: any;
  newTeamListItems: any;
  newVendorItems: any;
  vendorId: any;
  profileList: any;
  categoryList: any;
  clasificationList: any;
  triggerList: any;
  workList: any;
  statusList: any;
  teamPayload: any;
  patchResponse: any;
  timelineName: any;
  timelineList: any;
  newTimelineItems: any;
  newCustomTimeItems: any;
  filterSearchItems: any;
  keyOptionTitle: any;
  keyResultResponse: any;
  stepLabel: any;
  customParameterList:any;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder'
  };
  stepStates:any=[];

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  searchText = '';
  searchKeyText = '';
  maxChars = 100;
  ColumnMode = ColumnMode;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  hasChange: boolean=false;
  
  options2: any;
  filteredOptions2: any;
  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  spocOptions: any;
  programOptions: any;

  subPortfilteredOptions: any;
  spocFilteredOptions: any;
  programFilteredOptions: any;


  filterOptionType: Observable<any[]> | any;
  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  filterOptionSpoc: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;

  OpportunityFieldList:any=[];

  selectedOwnerDetails:any={};  
  subPortFolioChild:any[]=[];
  isRedirectFrom = false;
  projectID:any='';
  sub:any;

  context: any;

  filteredItems: any = [];

  filteredTeamList: any;
  createParameterForm: FormGroup | any;

  filteredVendorList: any;
  gdpCreateForm: FormGroup | any;

  filteredTimeLineList: any;
 
  okrArray: any;
  selectedOkr: any;
  breadcrumb: any;
  checkedFlag:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private ps: ProgramService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private dataService: DataService,
    private lodashService: LodashService,
    public dialog: MatDialog,
    private _formValidator:FormValidatorService,
    private breadCrumService: BreadcrumbService,
    private ds: DealStructureService
  ) {
    this.context = {
      componentParent: this,
    }; 
    this.createForm();
  }

  ngOnInit(): void {
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id']; 
      });
    setTimeout(() => {
      this.showNext = true;
    }, 1100)
    const perm: any = JSON.parse(localStorage.getItem('permission') || '{}');

    this.permissionsService.loadPermissions(perm.permissions);
    this.route.parent?.parent?.params.subscribe((res: any) => {
      this.dealId = atob(res.id)
      // this.getDealById();
    })
    this.data = {
      category: false,
      classification: false,
      triggers: false,
      work: false,
      fundingStatus: false,
    }
    this.customFunction();
    this.getOkr();
    
    this.username = JSON.parse(localStorage.getItem('permission') || '{}');
    // this.keyPatch();

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
  }
  async customFunction() {

    await this.getList();
      }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions2?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programFilteredOptions?.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  }
  initAutoComplete(): any {
    this.filterOptionType = this.setupForm.get('deal_type')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
    );
  this.filterOptionPortfolio = this.setupForm.get('portfolio')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
    );
    this.filterOptionProgram = this.setupForm.get('program')!.valueChanges
    .pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value?.name),
      map((name: any) => name ? this.programFilter(name) : this.programFilteredOptions?.slice())
    );   
  this.valuePatch(this.patchResponse);
  }

  createForm() {
    this.setupForm = this.formBuilder.group({
      deal_brief: [''],
      name: ['', [Validators.required]],
      deal_type: [''],
      portfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      owners: ['',[Validators.required]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]]
    });
    this.keyForm = this.formBuilder.group({
      goalTitle: ['', [Validators.required,this._formValidator.autocompleteObjectValidator]],
      goalDescription: ['', Validators.required],
      keyTitle: ['', Validators.required],
      keyDescription: ['', Validators.required],
      childKeyTitle: ['', Validators.required],
      // childKeyDescription: ['', Validators.required],
      primaryOwner: ['', Validators.required],
      secondaryOwner: [''],
      primaryChildOwner: ['', Validators.required],
      secondaryChildOwner: ['']
    });
   
    this.createParameterForm = this.formBuilder.array([]);
    this.gdpCreateForm = this.formBuilder.array([]);
   
    this.profileForm = this.formBuilder.group({
      category: ['', Validators.required],
      classification: ['', Validators.required],
      triggers: ['', Validators.required],
      work: ['', Validators.required],
      fundingStatus: [false, Validators.required],
    });
    
  }

  getDealById() {
    //this.spinner.show();
    
    this.ds.getDealDetails(this.dealId).subscribe((res: any) => {
      console.log(res);
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: 'Deal Structure', url :'/deal-structure'},
        { label: res?.portfolio?.parent_portfolio?.display_name, url: '/deal-structure', queryParams: {portfolio: res.portfolio?.parent_portfolio?.id}  },
        { label: res?.portfolio?.display_name, url: '/deal-structure', queryParams: {subportfolio: res?.portfolio?.id}  },
        { label: res?.program?.name, url: '/deal-structure',queryParams: {program: res?.program?.id} },
        { label: res.deal_name, url: '/deal-structure' }
      ];
      breadCrumbFirst.push(...breadCrumb)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);
  
      this.patchResponse = res;  
      localStorage.setItem('deal-info', JSON.stringify({
        id:this.patchResponse.id,
        deal_number: this.patchResponse.deal_number,
        deal_name: this.patchResponse.deal_name,
        deal_type: this.patchResponse.deal_type,
        program: this.patchResponse.program,
        program_id: this.patchResponse.program_id,
        portfolio:this.patchResponse.portfolio,
        tagDetails:this.patchResponse.tag_ids
      })); 

      this.ps.setStatusValue(res?.status);
      this.valuePatch(this.patchResponse);

      if(this.patchResponse?.status?.slug !='opportunity_draft'){
        this.stepper?.steps.forEach((step:any,index:number) => {
          this.stepStates[index] = 'done';
          step.completed = true;
        });
      }
   //   this.spinner.hide();
    }, error => {
   //   this.spinner.hide();
      throw error;
    })
  }
  getUser() {
    this.ps.getUser().subscribe((res: any) => {

      this.userList = JSON.parse(JSON.stringify(res.records));
      this.userList.map((i: any) => {
        i.full_name = `${i.first_name} ${i.last_name}`;
        i.full_name = i.full_name != ' ' ? i.full_name : i.username;
      });

    }, error => {
      this.spinner.hide();

      throw error;
    })
  }
  enableField(field_name:string){
    this.setupForm.get(field_name).enable();
  }
  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.setupForm.patchValue({
      subPortfolio: '',
      owners:''
    })
    this.subPortFolioChild =[];
    Object.keys(this.setupForm.controls).forEach((control:any)=>{
      if(control.includes('sub_portfolio_child')){
        this.setupForm.removeControl(control);
      }
    })
    
    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.setupForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
    );
  }  
  valuePatch(data: any) {  
      this.setupForm?.patchValue({
        name: data?.deal_name,
        deal_type: data?.deal_type,
        program: data?.program,
        deal_brief: data?.short_description,
        portfolio:data?.portfolio?.parent_portfolio,
        subPortfolio:data?.portfolio,
        owners:data?.portfolio?.owner_details?.username
      })

     
     this.setupForm.updateValueAndValidity();
    let portdata = this.options1?.find((e:any)=> e.id === data?.portfolio_details?.[0]?.id);
    this.subPortOptions = portdata?.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.setupForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions?.slice())
    );

    this.setupForm.get("name").disable();
   
     this.setupForm.get("owners").disable();
 

    const initialValue = this.setupForm.getRawValue();
    this.setupForm.valueChanges.subscribe((value:any) => {
      this.hasChange = Object.keys(initialValue).some(key => this.setupForm?.value[key] != initialValue[key])
    });

 
    if (data?.opportunity_key_results?.length > 0) {
      let keyArray: any[] = [];
      data.opportunity_key_results.forEach((element: any) => {
        keyArray.push(element.goal);
      });
      this.masterKeyResult = keyArray;
      this.masterKeyResultInitial = JSON.parse(JSON.stringify(keyArray));
      //this.getCustomkeyResults();
    }
    
    if (data) {
      this.profileForm.patchValue({
        category: data.category?.id,
        classification: data.classification?.id,
        triggers: data.trigger?.id,
        work: data.worktype?.id,
        fundingStatus: data.funding_status == true ? 0 : 1
      })
    }
    const profileFormValue = this.profileForm.value
    this.profileForm.valueChanges.subscribe((value:any) => {
      this.hasChange = Object.keys(profileFormValue).some(key => this.profileForm.value[key] != profileFormValue[key])
    });
    
    if(data?.status?.slug == 'opportunity_evaluating' || data?.status?.slug == 'opportunity_sow_signed'|| data?.status?.slug == 'opportunity_sow_signed' || data?.status?.slug == 'opportunity_cancelled'){
      this.disableFields =true;
      this.side = false;
      this.setupForm.disable();
      this.profileForm.disable();
      this.createParameterForm.disable();
      this.gdpCreateForm.disable();
      this.keyForm.disable();
    }else{this.disableFields =false;}
  }

  

  goalDesSelect(e: any) {
    if (e) {
      this.keyForm.patchValue({
        goalDescription: e.goalDescription
      })
    } else {
    }
  }

  keyDesSelect(e: any) {
    if (e) {
      this.keyForm.patchValue({
        keyDescription: e.keyDescription
      })
    } else {
    }
  }

  displayFn(data: any) {
    return data && data.goalTitle ? data.goalTitle : '';
  }
  displayFnName(data: any) {
    return data && data.name ? data?.name : data?.full_name;
  }
  onSelectionChangeSubportPortfolio(e: any) {    
    const subportFolioId = e.source.value.id;
    //this.subPortfilteredOptions = [];
    if (!subportFolioId) {
      return;
    }  
    let value = e.source.value;  
   
    this.subPortFolioChild =[];
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          this.setupForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));  
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{
          this.setupForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })
          this.selectedOwnerDetails = value.owner_details?.[0];
          this.selectedOwnerDetails['mail']=this.selectedOwnerDetails['email'];
        }
      });
    }else{
      this.setupForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })
      this.selectedOwnerDetails = value.owner_details?.[0];
      this.selectedOwnerDetails['mail']=this.selectedOwnerDetails['email'];
    }
  }
  onSelectionChangeSubportPortfolioChild(e:any){
    let value = e.source.value;
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          let index = this.subPortFolioChild.findIndex((e:any)=> e.key === key);
          if(index>-1)
            this.subPortFolioChild.splice(index , this.subPortFolioChild.length - index );
          this.setupForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));       
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{            
          this.setupForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })          
          this.selectedOwnerDetails = value.owner_details?.[0];
          this.selectedOwnerDetails['mail']=this.selectedOwnerDetails['email'];
        }
      });      
    }else{
      this.setupForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })      
      this.selectedOwnerDetails = value.owner_details?.[0];
      this.selectedOwnerDetails['mail']=this.selectedOwnerDetails['email'];
    }
  }
  selectedStepper(e: StepperSelectionEvent) {
    this.stepper.steps.forEach((step:any,index:number) => {
      if( index == e.selectedIndex){
        this.stepStates[index] = 'edit';
      }else if(!step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'number';
      }else if(step.completed && index != e.selectedIndex){
        this.stepStates[index] = 'done';
      }      
    });
    this.showNext = false;
    setTimeout(() => {
      this.showNext = true;
    }, 550)
    this.stepLabel = e.selectedStep.label;
    localStorage.setItem('stepLabel', this.stepLabel);
    if( this.patchResponse?.status?.slug !='opportunity_draft'){
      switch (e.previouslySelectedStep?.label) {
        case 'Setup':
          // && this.hasChange
          if(!this.disableFields)
            this.updateSetup();
            // this.updateProfile();
          this.hasChange = false;
          break;
        case 'Profile':
          // && this.hasChange
          if(!this.disableFields)
            // this.updateProfile();          
          this.hasChange = false;
          break;
        case 'Key Results':
          // const noChange = JSON.stringify(this.masterKeyResult) === JSON.stringify(this.masterKeyResultInitial);
          // && !noChange
          
          if(!this.disableFields )
            this.saveSelection();         
          this.hasChange = false;
          break;

      }
    }
    this.stepperValues(this.stepLabel);
  }

  stepperValues(stepLabel: any) {
    switch (stepLabel) {
      case 'Setup':
        this.stepperTitles = { title: 'Deal Setup', buttonName: 'Parameter' };
        break;
      case 'Profile':
        this.stepperTitles = { title: 'Deal Profile', buttonName: '' };
        // this.getProfileData();
        if(!this.disableFields && this.hasChange && this.patchResponse?.status?.slug=='opportunity_draft')
          this.updateSetup();
        this.hasChange = false;
        break;

      case 'Key Results':
        this.stepperTitles = { title: 'Deal Key Results', buttonName: 'Key Results' };
        // this.getCustomkeyResults();
        if(!this.disableFields && this.hasChange && this.patchResponse?.status?.slug=='opportunity_draft'){
          this.updateSetup();
        }
          // this.updateProfile();          
        this.hasChange = false;
        break;

      default:
        this.stepperTitles = { title: 'Deal Setup', buttonName: 'Parameter' };
    }
  }

  get f() {
    return this.setupForm.controls;
  }
  get t() {
    return this.f.setup_data as FormArray;
  }
  get g() {
    return this.keyForm.controls;
  }



  

  addColumn(item: any) {
    this.items = this.items.filter(e => e.id !== item?.id);
    this.updateItems = this.items
    this.selectedItems.push(item)
    if(this.setupForm.get(item?.field_name)){
      this.setupForm.removeControl(item?.field_name);
      this.setupForm.updateValueAndValidity();
    }
    this.setupForm.addControl(item?.field_name, new FormControl('', [Validators.required]));
    this.setupForm.get(item?.field_name).setValidators([Validators.required]);
    this.setupForm.get(item?.field_name).updateValueAndValidity();
    const tempItems = JSON.parse(this.newItems);
    const newItems = tempItems.filter((e: any) => e.id !== item?.id);
    this.newItems = JSON.stringify(newItems);
    return this.items;
  }
  removeColumn(item: any) {
    this.setupForm.get(item?.field_name)?.setValidators([]);
    this.setupForm.get(item?.field_name)?.setValue('');
    this.setupForm.get(item?.field_name)?.updateValueAndValidity();
    this.selectedItems = this.selectedItems.filter((e: any) => e.id !== item?.id)
    if (this.customNewItems.length > 0) {
      this.customNewItems = this.customNewItems.filter(e => e.id !== item?.id);
    }
    if (item?.custom || item?.custom_parameter) {
      this.customItems.push(item);
    } else {
      this.items.push(item);
    }
    this.newItems = JSON.stringify(this.items);
    this.newCustomItems = JSON.stringify(this.customItems);
  }
  

  addKeyColumn(item: any) {


    let slaveKeyResultdata = _.cloneDeep(this.slaveKeyResult);
    // below will find goal parent using goal id which will return respective goal parent
    let keyresultsdata = { ...slaveKeyResultdata.find((i: any) => i.goal_uuid == item.strategic_goal_uuid) };
    // below will find goals child data using key result unique id which will return respective goal parents child data
    let keyresultchilddata = keyresultsdata.key_results.filter((i: any) => {
      if (i.key_result_uuid == item.parent_key_result_uuid) {
        return i;
      }
    });
    // below will check whether goal unique id is equal to key result goal unique id if equal it will return true else false
    const isExist = this.masterKeyResult.some(e => e.goal_uuid == keyresultsdata.goal_uuid);
    // below will pass child data to parent
    keyresultsdata.key_results = keyresultchilddata;
    // below will find subchild data of parent using key result id which will return respective goal parent subchild
    let subchild = keyresultsdata.key_results[0].child_key_results.filter((el: any) => {
      if (el.key_result_uuid == item.key_result_uuid) {
        return el;
      }
    });


    // below will push to keyarr based on condition

    let subchildata = subchild[0];
    if (!isExist) {
      let keyclonedata = _.cloneDeep(keyresultsdata);
      this.masterKeyResult.push(keyclonedata);

      if (keyresultsdata.key_results != null) {
        this.masterKeyResult.filter((filterres: any) => {
          if (filterres.goal_uuid == subchildata.strategic_goal_uuid) {
            if (filterres.key_results.some((e: any) => e.key_result_uuid == subchildata.parent_key_result_uuid)) {
              let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata.parent_key_result_uuid);
              filterres.key_results[parentindex].child_key_results = subchild;
              let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
              filterres.key_results[parentindex].child_key_results = sortby
            }
          }
        })
      }
    } else {

      this.masterKeyResult.filter((filterres: any) => {
        if (filterres.goal_uuid == subchildata?.strategic_goal_uuid) {
          if (filterres.key_results.some((e: any) => e.key_result_uuid == subchildata?.parent_key_result_uuid)) {
            let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata?.parent_key_result_uuid);
            filterres.key_results[parentindex].child_key_results.push(subchild[0]);
            let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
            filterres.key_results[parentindex].child_key_results = sortby
          } else {
            filterres.key_results.push(keyresultchilddata[0]);
            let parentindex = filterres.key_results.findIndex((i: any) => i.key_result_uuid == subchildata?.parent_key_result_uuid);
            filterres.key_results[parentindex].child_key_results = subchild;
            let sortby = this.lodashService.orderBy(filterres.key_results[parentindex].child_key_results, "key_result_title", "asc");
            filterres.key_results[parentindex].child_key_results = sortby
          }
        }
      })      
    }
    for(let item in this.masterKeyResult){
      this.masterKeyResult[item]['key_results'] =this.sortKeyResults(this.masterKeyResult[item]['key_results'],'key_title')
      for(let child in this.masterKeyResult[item]['key_results']){
        this.masterKeyResult[item]['key_results'][child]['child_key_results'] =this.sortKeyResults(this.masterKeyResult[item]['key_results'][child]['child_key_results'],'key_title')
      } 
    } 
  }

  sortKeyResults(arr:any=[],key:any){
    arr.sort((a:any, b:any)=> {
      if(a[key] === b[key]){
        return 0;
      }else if (a[key] === null) {
        return 1;
      }else if (b[key] === null) {
        return -1;
      }else if (a[key] < b[key]) {
        return -1;
      }else{
        return 1;
      }
    });
    return arr;
  }

  // below will remove child key / parent key / goal form Master key Result which is center pane  

  removeKeyResult(item: any) {    
    this.masterKeyResult.filter((goal: any, goalindex: number) => {
      if (goal.goal_uuid == item.strategic_goal_uuid) {
        if (goal.key_results.length != 0) {
          goal.key_results.forEach((keyResultParent: any, keyResultParentindex: number) => {
            let childKeyResultIndex = keyResultParent.child_key_results.findIndex((child: any) => child.key_result_uuid == item.key_result_uuid)
            if (childKeyResultIndex !== -1) {
              keyResultParent.child_key_results.splice(childKeyResultIndex, 1);
            }
            if (keyResultParent.child_key_results.length == 0) {
              goal.key_results.splice(keyResultParentindex, 1);
            }
            if (goal.key_results.length == 0) {
              this.masterKeyResult.splice(goalindex, 1);
            }
          })
        } else {
          this.masterKeyResult.splice(goalindex, 1);
        }
      }
    })
    if (!item.goal_is_custom) {
      this.dataService.removeKeyMeasurmentMethod(item);
    } else {
      this.dataService.removeCustomKeyMeasurmentMethod(item);
    }
  }

  
  getProfileData(data:any) {
    // this.ps.getOpportunityFormData('opportunity_setup').subscribe((res: any) => {
      this.profileList = JSON.parse(JSON.stringify(data));
      this.profileList.map((i: any) => {
        if (i.field_name == "opportunity_category") {
          this.data.category = i.field_status;
          this.categoryList = i.field_values;
          this.data.disCategory = i.display_name;
          if (!i.field_status) {
            this.profileForm.removeControl('name');
          }
        }
        if (i.field_name == "opportunity_classification") {
          this.data.classification = i.field_status;
          this.clasificationList = i.field_values;
          this.data.disClassification = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('classification');
          }
        }
        if (i.field_name == "opportunity_trigger") {
          this.data.triggers = i.field_status;
          this.triggerList = i.field_values;
          this.data.disTriggers = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('triggers');
          }
        }
        if (i.field_name == "opportunity_work_type") {
          this.data.work = i.field_status;
          this.workList = i.field_values;
          this.data.disWork = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('work');
          }
        }
        if (i.field_name == "funding_status") {
          this.data.fundingStatus = i.field_status;
          this.statusList = i.field_type;
          this.data.disfundingStatus = i.display_name
          if (!i.field_status) {
            this.profileForm.removeControl('fundingStatus');
          }
        }
      })
    // }, error => {
    //   this.spinner.hide();
    //   throw error;
    // })
  }

 
  updateSetup() {
    
    let prefix = 'opportunity_'
    let form_data = this.setupForm.getRawValue();   
    let payload:any={}; 
    // let payload: any = {
    //   setup_data: this.setup_data,
    // }
    // payload.setup_data.opportunity_name= this.setupForm.value.name;
    // if (this.setupForm.value.opportunity) {
    //   payload.setup_data.opportunity_type = this.setupForm.value.opportunity.id
    // }
    
    // if (this.setupForm.value.portfolio) {
    //   let childID = this.subPortFolioChild[this.subPortFolioChild?.length-1]?.id;
    //   // payload.portfolio = childID>=0 ? childID:this.setupForm.value.subPortfolio.id;
    // }
    // for (let item in form_data) {
    //   if (item != "deal_brief") {
    //     if (item.startsWith(prefix)) {
    //       let key = item.slice(prefix.length);
    //       this.setup_data[key] = form_data[item]
    //     }
    //   }
    //   else {
    //     payload[item] = form_data[item]
    //   }

    //   // let custom_parameters:any = [];
    //   // this.createParameterForm.value.forEach((element:any) => {
    //   //   let param_id = this.customParameterList.filter((x:any) => x.id === element.custom_parameter.value);
    //   //   if(param_id.length > 0){
    //   //     custom_parameters.push({id:element.custom_parameter.value,name:'',value:element.custom_parameter_value.value});
    //   //   }else{
    //   //     custom_parameters.push({id:null,name:element.custom_parameter.value,value:element.custom_parameter_value.value});
    //   //   }
    //   // });

    // }
    payload.short_description = this.setupForm.value.deal_brief,
    payload.deal_type = this.setupForm.value?.deal_type,
    payload.portfolio_id = this.setupForm.value.subPortfolio?.id,
    payload.program_id = this.setupForm.value.program?.id,
    payload.category_id = this.profileForm.value.category,
    payload.classification_id = this.profileForm.value.classification,
    payload.trigger_id = this.profileForm.value.triggers,
    payload.worktype_id = this.profileForm.value.work,
    payload.funding_status = this.profileForm.value.fundingStatus == '0' ? true : false
    
    this.ds.updateDeal(this.dealId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.getSetupParameters();
    }, error => {
      this.spinner.hide();
      throw error;
    })
    this.searchText = '';
  }

  getSetupParameters() {
  // //  this.spinner.show();
  //   this.ps.getOpportunityFormData('opportunity_setup').subscribe((res: any) => {
  //   //  this.spinner.hide();
  //   }, error => {
  //   //  this.spinner.hide();
  //     throw error;
  //   })
  }

  updateProfile() {
    var profilePayload = {
      profile_data: {
        category: this.profileForm.value.category,
        classification: this.profileForm.value.classification,
        trigger: this.profileForm.value.triggers,
        work_type: this.profileForm.value.work,
        funding_status: this.profileForm.value.fundingStatus == '0' ? true : false
      }
    };
    this.ps.updateOpportunity(this.dealId, profilePayload).subscribe((res: any) => {
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
    this.searchText = '';
  }

 

  customPar(parameterName : any) {
    if (!!parameterName) {
      var payload1 = {
        name: parameterName,
        display_name: parameterName
      }
      this.ps.createCustomParameter(payload1).subscribe((res: any) => {
        if (res == false) {
          this.toastrService.error('UH OH! This parameter already exists, Please create a parameter that didn’t exist in the system');
          return;
        } else {
          this.toastrService.success('Custom parameter added successfully');
          // this.side = true;
        }
        return this.parameterName = '';
      }, error => {
        this.toastrService.error('Could not create a custom field');
        throw error;
      });
    }
    else {
      this.toastrService.error('Please enter a custom parameter');
      return this.parameterName;
    }
  }


  async getList(type?: any) {
    //this.spinner.show();
   
    combineLatest([

      this.ps.getOpportunityType().pipe(tap((res:any) => {
        this.options2 = res.opportunity_type;
        this.filteredOptions2 = this.options2;      
      })),
   
      this.ps.getOpportunityHierarchy().pipe(tap((res:any) => {
        this.options1 = res.records;
        this.filteredOptions1 = this.options1;
            
      })),
    
      this.ps.getProgramData().pipe(tap((res:any) => {
        this.programOptions = res;
          this.programFilteredOptions = this.programOptions;
        
            
      })
      )
   
   ]).subscribe((resultArray) => {
    this.initAutoComplete()

   })



    return new Promise((resolve, reject) => {
      this.ps.getOpportunityFormDataWithoutChache('opportunity_setup').subscribe((res: any) => {
      //  this.spinner.hide();
      this.getProfileData(res.records);
        let records= JSON.parse(JSON.stringify(res.records));
        var customRecords = records.filter((x: any) => x.field_name == 'custom_parameter' && x.field_status == true);
        this.customParameterList = records.filter((x: any) => x.field_name == 'custom_parameter' && x.field_status == true);
        this.customParameterList = this.customParameterList[0].field_values
        var checkCustom = records.filter((x: any) => x.field_name == 'custom_parameter' && x.field_status == false);
        this.showAddField = checkCustom.length ? false : true;
        this.customItems = [];
        this.customRecords = customRecords[0].field_values;
        this.customRecords.forEach(item => {
          var payload = {
            display_name: item.display_name,
            field_name: item.name,
            id: item.id,
            delete: item.delete,
            field_type: 'input',
            custom: true
          }
          this.customItems.push(payload);
          this.customItems = _.sortBy(this.customItems, 'id');
          if (type) {
            this.customItems.forEach(i => {
              this.selectedItems.forEach((e: any) => {
                if (i.id == e.id || i.id == e.custom_parameter) {
                  const index = this.customItems.findIndex(k => k.id == e.id || k.id == e.custom_parameter);
                  this.customItems.splice(index, 1);
                }
              })
              this.customItems = _.sortBy(this.customItems, 'id');
            })
          }
          this.customItems = this.customItems.reverse();
        });
        this.newCustomItems = JSON.stringify(this.customItems);
        
        if (!type && !this.initalLoad) {
          this.side = false;
          this.submitted = false;
          //this.spinner.show();
          this.initalLoad = true;
          this.parameterList = JSON.parse(JSON.stringify(res.records));
          this.filterSearchItems = this.parameterList.filter((i: any) => i.field_status == false);
  
          this.items = this.parameterList.filter(e => e.field_status == true);
     
          this.getDealById();
        }
        resolve(true);
      }, error => {
        this.spinner.hide();
        reject();
        this.toastrService.error('Could not get custom field');
      });
    });

 
  }
  getOkr(){
    this.spinner.show();
    this.ds.getDealKrData(this.dealId).subscribe((getOkr:any) => {
      getOkr.okr_hierarchy.forEach((objective: any) => {
        objective.level = "objective";
        objective.name = objective.title;
        if(objective.key_results.length > 0){
          objective.children = objective.key_results;
          objective.children.forEach((kr: any) => {
            kr.level = "kr";
            kr.name = kr.title;
            kr.objective_id = objective.id;
            if(kr.goals.length > 0){
              kr.children = kr.goals;
              kr.children.forEach((goals: any) => {
                goals.level = "goal";
                goals.name = goals.goal_name;
                goals.kr_id = kr.id;
                goals.objective_id = objective.id;

                if(getOkr.selected_goal_ids.findIndex((e: any) => e.goal_id == goals.id) > -1){
                  goals.selected = true;
                }else{
                  goals.selected = false;
                }
              })

              if(kr.children.filter((e: any) => e.selected === true).length == kr.children.length){
                kr.selected = true;
                kr.selection_type = 'all';
              }else if(kr.children.filter((e: any) => e.selected === true).length > 0){
                kr.selected = true;
                kr.selection_type = 'intermediate';
              }else{
                kr.selected = false;
                kr.selection_type = 'none';
              }
            }
          });

          if(objective.children.filter((e: any) => e.selection_type === 'all').length == objective.children.length){
            objective.selected = true;
            objective.selection_type = 'all';
          }else if(objective.children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || objective.children.filter((e: any) => e.selection_type === 'all').length > 0){
            objective.selected = true;
            objective.selection_type = 'intermediate';
          }else{
            objective.selected = false;
            objective.selection_type = 'none';
          }
        }
      });
      this.okrArray = getOkr.okr_hierarchy;
      this.selectedOkr = getOkr.selected_goal_ids;
      this.spinner.hide();      
    })
  }

  collapse(item: any){
    if(item.level == 'objective'){
        this.okrArray.forEach((element: any) => {
          if(element.id != item.id) element.expanded = false;
        });
      }else if(item.level == 'kr'){
        let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

        if(this.okrArray[krIndex].children.length > 0){
          this.okrArray[krIndex].children.forEach((element: any) => {
            if(element.id != item.id) element.expanded = false;
          });
        }
      }
    }

    selectGoals(item: any){
      if(item.level == 'objective'){
        this.okrArray.forEach((obj: any) => {
          if(obj.id == item.id) {
            obj.selected = item.selected;
            obj.selection_type = item.selected ? 'all' : 'none';

            obj.children.forEach((kr: any) => {
              kr.selected = item.selected;
              kr.selection_type = item.selected ? 'all' : 'none';

              kr.children.forEach((goal: any) => {
                goal.selected = item.selected;
                goal.selection_type = item.selected ? 'all' : 'none';
              });
            });
          }
        });
      }else if(item.level == 'kr'){
        let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);

        if(this.okrArray[krIndex].children.length > 0){
          this.okrArray[krIndex].children.forEach((kr: any) => {
            if(kr.id == item.id) {
              kr.selected = item.selected;
              kr.selection_type = item.selected ? 'all' : 'none';

              kr.children.forEach((goal: any) => {
                goal.selected = item.selected;
                goal.selection_type = item.selected ? 'all' : 'none';
              });
            }
          });

          if(this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length){
            this.okrArray[krIndex].selected = true;
            this.okrArray[krIndex].selection_type = 'all';
          }else if(this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.okrArray[krIndex].selected = true;
            this.okrArray[krIndex].selection_type = 'intermediate';
          }else{
            this.okrArray[krIndex].selected = false;
            this.okrArray[krIndex].selection_type = 'none';
          }
        }    
      }else if(item.level == 'goal'){
        let krIndex = this.okrArray.findIndex((e: any) => e.id == item.objective_id);
        let goalIndex = this.okrArray[krIndex].children.findIndex((e: any) => e.id == item.kr_id);

        if(this.okrArray[krIndex].children[goalIndex].children.length > 0){
          this.okrArray[krIndex].children[goalIndex].children.forEach((goal: any) => {
            if(goal.id == item.id) {
              goal.selected = item.selected;
              goal.selection_type = item.selected ? 'all' : 'none';
            }
          });
          

          if(this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length == this.okrArray[krIndex].children[goalIndex].children.length){
            this.okrArray[krIndex].children[goalIndex].selected = true;
            this.okrArray[krIndex].children[goalIndex].selection_type = 'all';
          }else if(this.okrArray[krIndex].children[goalIndex].children.filter((e: any) => e.selected === true).length > 0){
            this.okrArray[krIndex].children[goalIndex].selected = true;
            this.okrArray[krIndex].children[goalIndex].selection_type = 'intermediate';
          }else{
            this.okrArray[krIndex].children[goalIndex].selected = false;
            this.okrArray[krIndex].children[goalIndex].selection_type = 'none';
          }

          if(this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length == this.okrArray[krIndex].children.length){
            this.okrArray[krIndex].selected = true;
            this.okrArray[krIndex].selection_type = 'all';
          }else if(this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'intermediate').length > 0 || this.okrArray[krIndex].children.filter((e: any) => e.selection_type === 'all').length > 0){
            this.okrArray[krIndex].selected = true;
            this.okrArray[krIndex].selection_type = 'intermediate';
          }else{
            this.okrArray[krIndex].selected = false;
            this.okrArray[krIndex].selection_type = 'none';
          }
        }
      }
    }

  saveSelection(){
    let goalArray: any = [];

    this.okrArray.forEach((objective: any) => {
      objective?.children?.forEach((kr: any) => {
        kr?.children?.forEach((goal: any) => {
          if(goal.selected){
            goalArray.push(goal.id)
          }
        });
        
      });
    });

    let postdata = {
      deal_id: this.dealId,
      goal_ids: goalArray
    }

    this.ds.selectDealGoals(postdata).subscribe((getOkr:any) => {
         
    })
  }
  next(type: any) {
    if (type === 'Setup') {
      this.submitted = true;
      if (this.setupForm.invalid) {
        return;
      }
      this.profSubmitted = true;
      if (this.profileForm.invalid) {
        return;
      }
      // this.updateSetup();
    }  else if (type === 'Key Results') {
      this.keySubmit = true;
        this.saveSelection(); 
      this.router.navigate([`deal/`+btoa(this.dealId)+`/scope`]);
    }
   }

  getShortName(fullName: any) {
    return fullName.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }
  clickPrevious(){
    this.router.navigate([`deal/${this.dealId}/setup`])
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "deal"
    }
  }

  showEdit(){
    this.editShow = true
  }
}